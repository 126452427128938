/**
 * Combine all reducers in this file and export the combined reducers.
 */


import { combineReducers } from 'redux-immutable';

import languageProviderReducer from 'containers/LanguageProvider/reducer';
import auth from 'modules/auth/reducer';
import calculate from './pages/SafetyStockPage/calculate/reducer';
import material from 'modules/material/reducer';
import opportunitiesTable from 'pages/OpportunitiesPage/OpportunitiesTable/reducer';
import opportunities from 'pages/OpportunitiesPage/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
	return combineReducers({
		language: languageProviderReducer,
		opportunitiesTable,
		auth,
		calculate,
		material,
		opportunities,
		...injectedReducers
	});
}
