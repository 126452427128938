const urlPrefixFor = status => {
	switch (status) {
		case 'INIT':
			return 'calculate';
		case 'DRAFT':
			return 'edit';
		case 'IN PROGRESS':
			return 'calculate';
		case 'FINISHED':
			return 'results';
		default:
			return undefined;
	}
};

export function comparisonUrl(comparison, searchString='') {
	const prefix = urlPrefixFor(comparison.status);
	if (prefix) {
		return `/simulator/${prefix}/${comparison.id}${searchString}`;
	}
	return `/simulator${searchString}`;
}

export function comparisonGenericUrl(comparison, searchString='') {
	return `/simulator/${comparison.id}${searchString}`;
}
