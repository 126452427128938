import axios from 'axios';
import config from 'config.js';

const api = axios.create({
	baseURL: config.apiUrl,
	withCredentials: true,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	}
});

let cancelTokenSource = axios.CancelToken.source();

api.interceptors.request.use(
	(axiosConfig) => {
		if (axiosConfig.data instanceof FormData) {
			axiosConfig.headers['Content-Type'] = 'multipart/form-data';
		}
		axiosConfig.cancelToken = cancelTokenSource.token;
		return axiosConfig;
	},
	(error) => Promise.reject(error)
);

// Response interceptor for handling errors
api.interceptors.response.use(
	(response) => response,
	(error) => {
		let errorResponse = { error: 'An unexpected error occurred' };

		if (axios.isCancel(error)) {
			console.log('Request canceled:', error.message);
			errorResponse = { error: 'Request was canceled' };
		} else if (error.response) {
			// Case 1: Received response from server
			if (error.response.data.response) {
				const r = error.response.data.response;
				if (r.error) {
					errorResponse = { error: r.error };
				} else if (r.errors) {
					errorResponse = { error: Object.values(r.errors)[0] };
				}
			} else if (error.response.data.error) {
				errorResponse = { error: error.response.data.error };
			} else {
				// Case 2: No specific error message in response
				errorResponse = { error: error.response.statusText };
			}
		} else if (error.request) {
			// The request was made but no response was received
			errorResponse = { error: 'No response received from server' };
		} else {
			// Something happened in setting up the request that triggered an Error
			errorResponse = { error: error.message || error };
		}

		console.error('API Error:', errorResponse.error);

		return Promise.reject(error);
	}
);

export const cancelAllRequests = () => {
	cancelTokenSource.cancel();
	cancelTokenSource = axios.CancelToken.source();
};

export default api;
