import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';
import _ from 'lodash';
import React, { useState } from 'react';

import MultiColumnMenu from 'components/Menu/MultiColumnMenu';
import { co2Cell, coloredCurrencyCell, coloredPercentCell, columnKey, ReactTable } from 'components/ReactTable';
import { filterWords } from 'components/ReactTable/filters';
import { downloadTableXLSX } from 'components/ReactTable/helpers';
import 'components/ReactTable/styles.scss';
import { makeMaterialColumn, Material } from 'modules/material';
import { bulletColumn, materialColumns, parametersColumnsResults, percentColumn, savingsColumn } from 'pages/SimulatorEditionPage/Table';
import { stringWithDiff } from 'pages/SimulatorEditionPage/Table/format';
import { mergeClasses } from 'utils/classHelper';
import styles from 'pages/SimulatorEditionPage/Table/styles.scss';

export const defaultMaterialColumns = [
	'number',
	'name',
	'mrpController',
	'calculation.pureSavings',
	'calculation.orderSavings',
	'averageInventoryValue'
];
export const defaultParametersColumns = ['maximumOrderQuantity', 'minimumOrderQuantity', 'roundingValue', 'safetyStock', 'serviceLevel'];
export const defaultColumns = [...defaultMaterialColumns, ...defaultParametersColumns];

let currentData = {};

export default ({ comparison, data, onClick, toggled, setToggled, resetColumns, ...rest }) => {
	const toggle = column => setToggled(_.xor(toggled, [columnKey(column)]));
	const toggleAll = oColumns => {
		const keys = oColumns.map(columnKey);
		if (keys.find(i => toggled.indexOf(i) === -1)) {
			setToggled(_.union(toggled, keys));
		} else {
			_.pullAll(toggled, keys); // This method mutates
			setToggled([...toggled]);
		}
	};
	const [filtered, setFiltered] = useState([]);

	const savingsColumnFooter = { ...savingsColumn, Footer: sumFooter };
	const coloredSavingsColumn = { ...savingsColumnFooter, Cell: coloredCurrencyCell };
	const coloredPercentSavings = { ...percentColumn, Cell: coloredPercentCell };

	const savingsColumns = [
		{ ...coloredSavingsColumn, accessor: 'calculation.pureSavings', Header: 'Total savings' },
		{ ...coloredSavingsColumn, accessor: 'calculation.supplyChainSavings', Header: 'Supply chain savings' },
		{ ...coloredPercentSavings, accessor: 'calculation.supplyChainSavingsPercent', Header: 'Supply chain savings %' },
		{ ...coloredSavingsColumn, accessor: 'calculation.quantityDiscounts', Header: 'Quantity discounts' },
		{ ...coloredSavingsColumn, accessor: 'calculation.orderSavings', Header: 'Order savings' },
		{ ...coloredPercentSavings, accessor: 'calculation.orderSavingsPercent', Header: 'Order savings %' },
		{ ...coloredSavingsColumn, accessor: 'calculation.inventorySavings', Header: 'Inventory savings' },
		{ ...coloredPercentSavings, accessor: 'calculation.inventorySavingsPercent', Header: 'Inventory savings %' },
		{ ...coloredSavingsColumn, accessor: 'calculation.scrapSavings', Header: 'Scrap savings' },
		{ ...coloredPercentSavings, accessor: 'calculation.scrapSavingsPercent', Header: 'Scrap savings %' }
	];
	const costsColumns = [
		{ ...savingsColumnFooter, accessor: 'calculation.SumOrderCost', Header: 'Order cost' },
		{ ...savingsColumnFooter, accessor: 'calculation.SumCarryingCost', Header: 'Inventory cost' },
		{ ...savingsColumnFooter, accessor: 'calculation.SumScrapCost', Header: 'Scrap cost' },
		{ ...savingsColumnFooter, accessor: 'calculation.SumPurchasingCost', Header: 'Purchasing cost', minWidth: 90 },
		{ ...savingsColumnFooter, accessor: 'calculation.SupplyChainCost', Header: 'Supply chain cost' },
		{ ...savingsColumnFooter, accessor: 'calculation.OrdersCount', Header: 'Number of orders', Cell: null },
		{
			...savingsColumnFooter,
			id: 'averageInventoryValue',
			accessor: m => m.calculation.AverageInventory * m.accountingPrice,
			Header: 'Avg. inventory value'
		},
		{ ...percentColumn, accessor: 'calculation.serviceLevel', Header: 'Service level' }
	];
	const co2Columns = [
		{ ...savingsColumnFooter, accessor: 'calculation.CO2', Header: 'Total GHG', Cell: co2Cell },
		{ ...savingsColumnFooter, accessor: 'calculation.CO2Transport', Header: 'Transport GHG', Cell: co2Cell },
		{ ...savingsColumnFooter, accessor: 'calculation.CO2Warehouse', Header: 'Warehouse GHG', Cell: co2Cell },
		{ ...makeMaterialColumn(Material.transportMode), Cell: stringWithDiff },
		{ ...makeMaterialColumn(Material.warehouse), Cell: stringWithDiff, minWidth: 90 }
	];

	// eslint-disable-next-line no-param-reassign,no-return-assign
	[materialColumns, savingsColumns, costsColumns, parametersColumnsResults, co2Columns].forEach(cols =>
		cols.forEach(m => (m.show = toggled.indexOf(columnKey(m)) > -1)));

	// Add 'Total' footer in the first visible column
	const col = _.first(materialColumns.filter(c => c.show));
	if (col) {
		col.Footer = <strong>Total</strong>;
	} else {
		// TODO: where should we display Total if there is no material column?
	}

	const columns = [
		bulletColumn,
		{ Header: 'Material Data', headerClassName: styles.MetaHeader, columns: materialColumns },
		{ Header: 'Savings', headerClassName: mergeClasses(styles.MetaHeader, styles.CurrentPlanHeader), columns: savingsColumns },
		{ Header: 'Costs & other', headerClassName: mergeClasses(styles.MetaHeader, styles.CurrentPlanHeader), columns: costsColumns },
		{ Header: 'CO2', headerClassName: mergeClasses(styles.MetaHeader, styles.CurrentPlanHeader), columns: co2Columns },
		{ Header: 'Master data', headerClassName: styles.MetaHeader, columns: parametersColumnsResults }
	];

	const editedParametersColumns = parametersColumnsResults.filter(column => comparison.editedColumns.includes(column.id));

	return (
		<>
			<Grid container justifyContent="flex-end">
				<Grid item>
					<ButtonGroup>
						<MultiColumnMenu columns={columns} onSelect={toggle} onSelectAll={toggleAll} reset={resetColumns}>
							Columns
						</MultiColumnMenu>
						<Button onClick={() => downloadTableXLSX(currentData,
							comparison.description || 'simulation',
							{ onlyVisible: true, withOriginals: editedParametersColumns })} startIcon={<GetAppIcon />}>
							Download
						</Button>
					</ButtonGroup>
				</Grid>
			</Grid>
			<ReactTable
				className={mergeClasses(styles.SelectReactTable, '-hover')}
				columns={columns}
				data={data}
				filtered={filtered}
				filterMethod={filterWords}
				getTrProps={(state, row) => ({ onClick: () => onClick(row) })}
				onFilteredChange={f => setFiltered(f)}
				{...rest}>
				{(state, makeTable) => {
					currentData = state;
					return makeTable();
				}}
			</ReactTable>
		</>
	);
};

const sumFooter = ({ column, data: currentData }) => {
	const { id, Cell } = column;
	const value = _.sum(currentData.map(row => row[id]));
	return Cell && currentData[0] ? Cell({ value, original: currentData[0]._original }) : value;
};
