import { toast } from 'react-toastify';
import React from 'react';

export async function copyToClipboard(text, successMessage = true) {
	try {
		await navigator.clipboard.writeText(text);
		if(successMessage) toast.success(<><strong>Copied to clipboard: </strong>{text}</>);
	} catch (err) {
		console.error('Async: Could not copy text: ', err);
	}
}
