/**
 *
 * ResultsWaterfall
 *
 */

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { PARAMETERS_CONFIG } from 'modules/scenario';
import { frequencyToUnit } from 'utils/dates';
import { formatPrice, formatShortNumber, formatShortQuantity } from 'utils/format';
import { camelCaseToTitle } from 'utils/string';
import styles from './styles.scss';

const unitToFormat = unit => {
	const mapper = {
		currency: formatPrice,
		'%': v => formatShortNumber(100 * v),
		unit: formatShortQuantity
	};
	return mapper[unit] || formatShortNumber;
};

const safetyStockStyle = (material, field) => {
	if ((field === 'safetyStock' || field === 'serviceLevel') && material.safetyStockFixedVariable !== field) {
		return styles.Calculated;
	}
	return '';
};

const ParametersDiff = ({ label, material }) => (
	<div className={styles.Diff}>
		{_.map(PARAMETERS_CONFIG, (cfg, field) => {
			if (material[field] !== material.base[field]) {
				const { type } = cfg;
				let { unit } = cfg;
				const format = unitToFormat(unit);
				if (unit === 'currency') unit = material.currency;
				if (unit === 'unit') unit = material.unit; // eslint-disable-line prefer-destructuring
				if (unit === 'frequency') unit = frequencyToUnit(material.frequency);
				switch (type) {
					case 'number':
						return (
							<div key={field}>
								<div className={styles.Title}>{label || camelCaseToTitle(field)}</div>
								<div className={styles.Params}>
									<div className={styles.Old}>
										<div>{format(material.base[field]) || '-'}</div>
										<div className={styles.Unit}>{unit}</div>
									</div>
									<div className={styles.Separator} />
									<div className={styles.New}>
										<div className={safetyStockStyle(material, field)}>{format(material[field]) || '-'}</div>
										<div className={styles.Unit}>{unit}</div>
									</div>
								</div>
							</div>
						);
					case 'boolean':
						return (
							<div key={field}>
								<div className={styles.Title}>{label || camelCaseToTitle(field)}</div>
								<div key={field} className={styles.Boolean}>
									{material[field] ? 'Yes' : 'No'}
								</div>
							</div>
						);
					default:
						return <div key={field} />;
				}
			}
		})}
	</div>
);
ParametersDiff.propTypes = {
	material: PropTypes.object.isRequired
};

export default ParametersDiff;
