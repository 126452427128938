import _ from 'lodash';
import { relativeDiff } from 'utils/format';
import { Material } from './material';

const extractableFields = [
	'AvailableInventory',
	'AverageInventory',
	'AveragePositiveInventory',
	'CO2',
	'CO2Transport',
	'CO2Warehouse',
	'CO2Footprint',
	'DeliveredInventory',
	'Deliveries',
	'Orders',
	'OrdersCount',
	'PlanAlerts',
	'Scrap',
	'ShiftedInventory',
	'AveragePrice',
	'SumCarryingCost',
	'SumOrderCost',
	'SumPurchasingCost',
	'RawPurchasingCost',
	'SumScrapCost',
	'SupplyChainCost',
	'TotalCost',
	'TotalCostAdjusted'
];

const savingsFields = [
	'endOfPeriodDifferential',
	'inventorySavings',
	'orderSavings',
	'pureSavings',
	'pureSavingsPercent',
	'quantityDiscounts',
	'scrapSavings',
	'supplyChainSavings',
	'totalSavings',
	'totalSavingsPercent'
];

export function extractScenario(materials, extract = 'genLots') {
	const buildExtractedCalculation = calculation => {
		const res = {};
		_.forEach(calculation, (value, field) => {
			let mightAdd = true;
			extractableFields.forEach(exField => {
				if (_.endsWith(field, exField)) {
					// The field is one we want, make sure we have the one we actually need to extract
					if (_.startsWith(field, extract)) {
						res[exField] = value;
					}
					mightAdd = false;
				}
			});
			if (mightAdd) {
				// Don't keep any savings, they are computed dynamically
				if (!_.includes(savingsFields, field)) {
					res[field] = value;
				}
			}
		});

		// FIXME The pattern broke, fixedOrderDeliveries was removed alongside genLotsDeliveries and comparisonDeliveries
		_.extend(res, _.pick(calculation, ['fixedOrderDeliveries', 'qmLotDeliveries']));
		res.firstOrder = extract === 'genLots' ? calculation.firstOrderGenLots : calculation.firstOrderSAP;

		return res;
	};

	return materials.map(m => ({
		...m,
		calculation: buildExtractedCalculation(m.calculation)
	}));
}

export const extractAllScenariosFromComparison = comparison => {
	if (!comparison || !comparison.scenarios) return {};
	const scenarios = _.fromPairs(comparison.scenarios.map(s => [s.id, extractScenario(s.materials)]));
	return {
		SAP: extractScenario(comparison.materials, 'comparison'),
		genLots: extractScenario(comparison.materials, 'genLots'),
		...scenarios
	};
};

export function compareScenarios(compScenario, baseScenario) {
	return compScenario.map(comp => {
		const base = _.find(baseScenario, b => b.number === comp.number);
		const diff = field => base.calculation[field] - comp.calculation[field];
		const relativeFieldDiff = field => -relativeDiff(comp.calculation[field], base.calculation[field]);

		const minOrderQuantity = _.min([_.sum(comp.calculation.Orders), _.sum(base.calculation.Orders)]);
		const deltaAveragePrice = diff('AveragePrice');

		return {
			...comp,
			calculation: {
				...comp.calculation,
				inventorySavings: diff('SumCarryingCost'),
				inventorySavingsPercent: relativeFieldDiff('SumCarryingCost'),
				orderSavings: diff('SumOrderCost'),
				orderSavingsPercent: relativeFieldDiff('SumOrderCost'),
				pureSavings: diff('SupplyChainCost') + deltaAveragePrice * minOrderQuantity,
				pureSavingsPercent: relativeFieldDiff('TotalCost'),
				quantityDiscounts: deltaAveragePrice * minOrderQuantity,
				scrapSavings: diff('SumScrapCost'),
				scrapSavingsPercent: relativeFieldDiff('SumScrapCost'),
				supplyChainSavings: diff('SupplyChainCost'),
				supplyChainSavingsPercent: relativeFieldDiff('SupplyChainCost'),
				totalSavings: diff('SumCarryingCost') + diff('SumOrderCost') + diff('SumPurchasingCost')
				// totalSavingsPercent: 0 // TODO if needed
			}
		};
	});
}

export function sumSavings(materials) {
	const sumAcrossMaterials = field => _.sum(materials.map(m => m.calculation[field]));
	return {
		averagePositiveInventoryValue: _.sum(materials.map(m => m.accountingPrice * m.calculation.AveragePositiveInventory)),
		carryingCost: sumAcrossMaterials('SumCarryingCost'),
		inventorySavings: sumAcrossMaterials('inventorySavings'),
		orderSavings: sumAcrossMaterials('orderSavings'),
		orderCost: sumAcrossMaterials('SumOrderCost'),
		ordersCount: sumAcrossMaterials('OrdersCount'),
		pureSavings: sumAcrossMaterials('pureSavings'),
		purchasingCost: sumAcrossMaterials('SumPurchasingCost'),
		quantityDiscounts: sumAcrossMaterials('quantityDiscounts'),
		rawPurchasingCost: sumAcrossMaterials('RawPurchasingCost'),
		scrapSavings: sumAcrossMaterials('scrapSavings'),
		scrapCost: sumAcrossMaterials('SumScrapCost'),
		supplyChainSavings: sumAcrossMaterials('supplyChainSavings'),
		supplyChainCost: sumAcrossMaterials('SupplyChainCost'),
		totalSavings: sumAcrossMaterials('totalSavings'),
		CO2: sumAcrossMaterials('CO2'),
		CO2Transport: sumAcrossMaterials('CO2Transport'),
		CO2Warehouse: sumAcrossMaterials('CO2Warehouse')
		// CO2Footprint: sumAcrossMaterials('CO2Footprint'),
	};
}

export const PARAMETERS = [
	'carryingCost',
	'carryingCostWeight',
	'coverageDays',
	'endOfYear',
	'fixedOrderSize',
	'horizon',
	'transportMode',
	'warehouse',
	'maxCoverage',
	'strictMaxCoverage',
	'maxInventory',
	'maximumOrderQuantity',
	'minimumOrderQuantity',
	'orderCost',
	'orderCostWeight',
	'pricePerUnitSingle',
	'qualityControlLeadTime',
	'roundingValue',
	'safetyStock',
	'safetyTime',
	'serviceLevel',
	'shelfLife',
	'supplierLeadTime',
	'strictAboutSafetyStock'
];
export const PARAMETERS_CONFIG = _.pickBy(Material, (config, key) => PARAMETERS.indexOf(key) !== -1);

// Augment the material with the base material
export const materialsEqual = (first, second) => {return PARAMETERS.every(param => first[param] === second[param])};
export const isEditedMaterial = material => !materialsEqual(material, material.base);
