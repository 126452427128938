import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';

import {
	booleanCell,
	cell,
	currencyCell,
	dateCell,
	ellipsisCell,
	exactCurrencyCell,
	frequencyCell,
	percentCell,
	supplierCell,
	unitCell
} from 'components/ReactTable';
import { filterSuppliers, filterWords } from 'components/ReactTable/filters';
import messages from 'pages/MaterialPage/messages';
import { camelCaseToTitle } from 'utils/string';

export const Material = {
	baseInventory: { type: 'number' },
	carryingCost: { type: 'number', unit: '%', tooltip: messages.CarryingCostHelp },
	carryingCostWeight: { type: 'number', unit: '', tooltip: messages.CarryingCostWeightHelp },
	coverageDays: { type: 'number', unit: 'w/days', tooltip: messages.CoverageDaysHelp },
	currency: {},
	endOfYear: { type: 'boolean' },
	fixedOrderSize: { type: 'number', unit: 'unit', label: 'Fixed order quantity', tooltip: messages.FixedOrderSizeHelp },
	horizon: { type: 'number', unit: 'days' },
	maxCoverage: { type: 'number', unit: 'frequency', label: 'Max. coverage' },
	maxInventory: { type: 'number', unit: 'unit', label: 'Max. inventory' },
	maximumOrderQuantity: { type: 'number', unit: 'unit', label: 'Max. order quantity', tooltip: messages.MaximumOrderQuantityHelp },
	minimumOrderQuantity: { type: 'number', unit: 'unit', label: 'Min. order quantity', tooltip: messages.MinimumOrderQuantityHelp },
	mrpController: { tableConfig: { filterable: true, filterMethod: filterWords, Cell: ellipsisCell } },
	mrpType: { tableConfig: { filterable: true, filterMethod: filterWords } },
	name: { tableConfig: { filterable: true, filterMethod: filterWords, Cell: ellipsisCell, minWidth: 160 } },
	number: { tableConfig: { filterable: true, filterMethod: filterWords, Cell: ellipsisCell } },
	orderCost: { type: 'number', unit: 'currency', tooltip: messages.OrderCostHelp },
	orderCostWeight: { type: 'number', unit: '', tooltip: messages.OrderCostWeightHelp },
	phaseout: { type: 'date' },
	phaseoutDuration: { type: 'number', unit: 'days' },
	pricePerUnitSingle: {
		type: 'number',
		unit: 'currency',
		label: 'Standard price', // 'Price per unit'
		// tooltip: messages.PricePerUnitHelp
		tableConfig: { Cell: exactCurrencyCell }
	},
	purchasingGroup: { tableConfig: { filterable: true, filterMethod: filterWords, minWidth: 90 } },
	procurementTypeCode: { label: 'Procurement type', tableConfig: { filterable: true, filterMethod: filterWords, minWidth: 100 } },
	qualityControlLeadTime: {
		type: 'number',
		unit: 'w/days',
		label: 'GR processing time',
		tooltip: messages.QualityControlLeadTimeHelp,
		tableConfig: { minWidth: 90 }
	},
	roundingValue: { type: 'number', unit: 'unit', tooltip: messages.RoundingValueHelp },
	safetyStock: { type: 'number', unit: 'unit', tooltip: messages.SafetyStockHelp },
	safetyTime: { type: 'number', unit: 'w/days', tooltip: messages.SafetyTimeHelp },
	serviceLevel: { type: 'number', unit: '%' },
	supplierLeadTime: {
		type: 'number',
		unit: 'days',
		label: 'Planned delivery time',
		tooltip: messages.SupplierLeadTimeHelp,
		tableConfig: { minWidth: 80 }
	},
	strictAboutSafetyStock: {
		type: 'boolean',
		label: 'Allow order before fixed order',
		tableConfig: { minWidth: 100 }
	},
	shelfLife: { type: 'number', unit: 'days', tooltip: messages.ShelfLifeHelp },
	specialProcurementTypeCode: {
		label: 'Special procurement type',
		tableConfig: { filterable: true, filterMethod: filterWords, minWidth: 100 }
	},
	storageName: { tableConfig: { filterable: true, filterMethod: filterWords } },
	storageType: { tableConfig: { filterable: true, filterMethod: filterWords } },
	strictMaxCoverage: { type: 'boolean', label: 'Strict max. coverage' },
	suppliers: {
		tableConfig: {
			filterable: true,
			Cell: supplierCell,
			filterMethod: filterSuppliers,
			minWidth: 140,
			sortMethod: (suppliersA, suppliersB) => {
				const allA = suppliersA.map(s => s.name).join(' - ');
				const allB = suppliersB.map(s => s.name).join(' - ');
				return allA.localeCompare(allB);
			}
		}
	},
	transportMode: { type: 'string', options: ['truck', 'boat', 'plane'] },
	warehouse: { type: 'string', options: ['ambient', 'refrigerated'], tableConfig: { minWidth: 90 } }
	// accountingPrice: { tooltip: messages.StandardPriceHelp },
	// baseInventory: { tooltip: messages.BaseInventoryHelp },
	// batches: { tooltip: messages.BatchesHelp },
	// quantity: { tooltip: messages.QuantityHelp }, // Quantity discounts
	// price: { tooltip: messages.PriceHelp } // Quantity discounts
};

// const Tooltip = injectIntl(({ intl, title, label }) => <>{label}{' '}<QuestionMarkTooltip title={intl.formatMessage(title)} /></>);
const CTooltip = injectIntl(({ intl, title, label }) => (
	<Tooltip title={intl.formatMessage(title)} className="">
		<div>{label}</div>
	</Tooltip>
));

_.forEach(Material, (m, key) => {
	m.field = key;
	m.label = m.label || camelCaseToTitle(key);
	m.labelTooltip = m.tooltip ? <CTooltip title={m.tooltip} label={m.label} /> : m.label;
	if (m.unit === 'w/days') m.longUnit = 'workings days';

	const typeToCell = { '%': percentCell, unit: unitCell, currency: currencyCell, frequency: frequencyCell };
	let tableCell;
	if (m.type === 'boolean') tableCell = booleanCell;
	if (m.type === 'date') tableCell = dateCell;
	if (m.type === 'number') tableCell = typeToCell[m.unit] || cell(m.longUnit || m.unit);
	m.tableConfig = { ...(tableCell ? { Cell: tableCell } : {}), ...m.tableConfig };
});

export const makeMaterialColumn = parameter => ({
	accessor: parameter.field,
	id: parameter.field,
	Header: parameter.labelTooltip,
	...parameter.tableConfig
});

export const Alerts = {
	aboveMaxOrderQuantity: { level: 'warn', label: 'Order above maximum order quantity' },
	belowMinOrderQuantity: { level: 'warn', label: 'Order below minimum order quantity' },
	fixedOrderSize: { level: 'warn', label: 'Fixed order size not respected' },
	belowSSAvailableStock: { level: 'warn', label: 'Available stock below safety stock' },
	belowSSDeliveredStock: { level: 'warn', label: 'Delivered stock below safety stock' },
	maxCoverage: { label: 'Max. coverage not respected' },
	maxInventory: { label: 'Max. inventory not respected' },
	negativeAvailableStock: { level: 'error', label: 'Negative available stock' },
	negativeDeliveredStock: { level: 'error', label: 'Negative delivered stock' },
	noConsumption: { level: 'info', label: 'No order recommended due to low consumption' },
	reorderDueToScrap: { level: 'error', label: 'Reorder due to scrap' },
	roundingValueViolated: { label: 'Rounding value not respected' }
};
