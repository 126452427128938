import _ from 'lodash';
import React from 'react';
import ReactTableO from 'react-table';

import { co2Cell, currencyCell, percentCell, SelectInput, TableDefault } from 'components/ReactTable';
import { filterWords } from 'components/ReactTable/filters';
import tableStyles from 'components/ReactTable/styles.scss';
import { makeMaterialColumn, Material } from 'modules/material';
import selectTable from 'react-table/lib/hoc/selectTable';
import { mergeClasses } from 'utils/classHelper';
import {
	boldIfEdited,
	calculated,
	currencyWithDiff,
	emptyIfNotEqual,
	frequencyWithDiff,
	ifEqualElse,
	percentWithDiff,
	stringWithDiff,
	unitWithDiff,
	withDiff
} from './format';
import styles from './styles.scss';

const ReactTable = selectTable(ReactTableO);

export const defaultMaterialColumns = [
	'number',
	'name',
	'mrpController',
	'base.calculation.pureSavings',
	'base.calculation.orderSavings',
	'averageInventoryValue'
];
export const defaultParametersColumns = ['maximumOrderQuantity', 'minimumOrderQuantity', 'roundingValue', 'safetyStock'];

export const defaultColumns = [...defaultMaterialColumns, ...defaultParametersColumns];

// Bullets to indicate filter state
export const bulletColumn = {
	accessor: 'edited',
	width: 20,
	Filter: <></>,
	filterMethod: undefined, // Use default method
	Cell: ({ value }) => <>{value && <span className={tableStyles.Bullet} />}</>
};

export const materialColumns = [
	makeMaterialColumn(Material.number),
	{ ...makeMaterialColumn(Material.name), Cell: boldIfEdited },
	makeMaterialColumn(Material.mrpController),
	makeMaterialColumn(Material.procurementTypeCode),
	makeMaterialColumn(Material.specialProcurementTypeCode),
	makeMaterialColumn(Material.purchasingGroup),
	makeMaterialColumn(Material.mrpType),
	makeMaterialColumn(Material.storageType),
	makeMaterialColumn(Material.storageName),
	makeMaterialColumn(Material.suppliers)
];

const parametersColumnsFactory = isResults => [
	{ ...makeMaterialColumn(Material.roundingValue), Cell: unitWithDiff },
	{ ...makeMaterialColumn(Material.minimumOrderQuantity), Cell: unitWithDiff },
	{ ...makeMaterialColumn(Material.maximumOrderQuantity), Cell: unitWithDiff },
	{ ...makeMaterialColumn(Material.fixedOrderSize), Cell: unitWithDiff },
	{
		...makeMaterialColumn(Material.safetyStock),
		Cell: isResults
			? ifEqualElse('safetyStockFixedVariable', 'safetyStock')(unitWithDiff, calculated(unitWithDiff))
			: emptyIfNotEqual('safetyStockFixedVariable', 'safetyStock')(unitWithDiff)
	},
	{
		...makeMaterialColumn(Material.serviceLevel),
		Cell: isResults
			? ifEqualElse('safetyStockFixedVariable', 'serviceLevel')(percentWithDiff, calculated(percentWithDiff))
			: emptyIfNotEqual('safetyStockFixedVariable', 'serviceLevel')(percentWithDiff)
	},
	{ ...makeMaterialColumn(Material.safetyTime), Cell: withDiff('Working days') },
	{ ...makeMaterialColumn(Material.coverageDays), Cell: withDiff('Working days') },
	{ ...makeMaterialColumn(Material.maxInventory), Cell: unitWithDiff },
	{ ...makeMaterialColumn(Material.supplierLeadTime), Cell: withDiff('Calendar days') },
	{ ...makeMaterialColumn(Material.qualityControlLeadTime), Cell: withDiff('Workings days') },
	makeMaterialColumn(Material.strictAboutSafetyStock),
	{ ...makeMaterialColumn(Material.shelfLife), Cell: withDiff('Calendar days') },
	makeMaterialColumn(Material.endOfYear),
	{ ...makeMaterialColumn(Material.pricePerUnitSingle), Cell: currencyWithDiff },
	// { accessor: 'accountingPrice', Header: 'accountingPrice', Cell: cellWithDiff }, // FIXME standard price ?
	{ ...makeMaterialColumn(Material.orderCost), Cell: currencyWithDiff },
	{ ...makeMaterialColumn(Material.carryingCost), Cell: percentWithDiff },
	{ ...makeMaterialColumn(Material.orderCostWeight), Cell: withDiff() },
	{ ...makeMaterialColumn(Material.carryingCostWeight), Cell: withDiff() },
	{ ...makeMaterialColumn(Material.maxCoverage), Cell: frequencyWithDiff },
	makeMaterialColumn(Material.strictMaxCoverage),
	{ ...makeMaterialColumn(Material.horizon), Cell: withDiff() }
	// { accessor: 'fixed orders', Header: 'Fixed orders', Cell: 'N/A' } // FIXME What is this one?
];

export const parametersColumns = parametersColumnsFactory(false);
export const parametersColumnsResults = parametersColumnsFactory(true);

export const savingsColumn = { Cell: currencyCell, className: styles.CurrentPlan, headerClassName: styles.CurrentPlanHeader };
export const percentColumn = { ...savingsColumn, Cell: percentCell };
const savingsColumns = [
	{ ...savingsColumn, accessor: 'base.calculation.pureSavings', Header: 'Total savings' },
	{ ...savingsColumn, accessor: 'base.calculation.supplyChainSavings', Header: 'Supply chain savings' },
	{ ...percentColumn, accessor: 'base.calculation.supplyChainSavingsPercent', Header: 'Supply chain savings %' },
	{ ...savingsColumn, accessor: 'base.calculation.quantityDiscounts', Header: 'Quantity discounts' },
	{ ...savingsColumn, accessor: 'base.calculation.orderSavings', Header: 'Order savings' },
	{ ...percentColumn, accessor: 'base.calculation.orderSavingsPercent', Header: 'Order savings %' },
	{ ...savingsColumn, accessor: 'base.calculation.inventorySavings', Header: 'Inventory savings' },
	{ ...percentColumn, accessor: 'base.calculation.inventorySavingsPercent', Header: 'Inventory savings %' },
	{ ...savingsColumn, accessor: 'base.calculation.scrapSavings', Header: 'Scrap savings' },
	{ ...percentColumn, accessor: 'base.calculation.scrapSavingsPercent', Header: 'Scrap savings %' }
];
const costsColumns = [
	{ ...savingsColumn, accessor: 'base.calculation.comparisonSumOrderCost', Header: 'Order cost' },
	{ ...savingsColumn, accessor: 'base.calculation.comparisonSumCarryingCost', Header: 'Inventory cost' },
	{ ...savingsColumn, accessor: 'base.calculation.comparisonSumScrapCost', Header: 'Scrap cost' },
	{ ...savingsColumn, accessor: 'base.calculation.comparisonSumPurchasingCost', Header: 'Purchasing cost', minWidth: 90 },
	{ ...savingsColumn, accessor: 'base.calculation.comparisonSupplyChainCost', Header: 'Supply chain cost' },
	{ ...savingsColumn, accessor: 'base.calculation.comparisonOrdersCount', Header: 'Number of orders', Cell: null },
	{
		...savingsColumn,
		id: 'averageInventoryValue',
		accessor: m => m.base.calculation.comparisonAverageInventory * m.accountingPrice,
		Header: 'Avg. inventory value'
	},
	{ ...percentColumn, accessor: 'base.calculation.serviceLevel', Header: 'Service level' }
];

const c02Columns = [
	{ ...savingsColumn, accessor: 'base.calculation.comparisonCO2', Header: 'Total GHG', Cell: co2Cell },
	{ ...savingsColumn, accessor: 'base.calculation.comparisonCO2Transport', Header: 'Transport GHG', Cell: co2Cell },
	{ ...savingsColumn, accessor: 'base.calculation.comparisonCO2Warehouse', Header: 'Warehouse GHG', Cell: co2Cell, minWidth: 90 },
	{ ...makeMaterialColumn(Material.transportMode), Cell: stringWithDiff },
	{ ...makeMaterialColumn(Material.warehouse), Cell: stringWithDiff }
];

let resolvedData;

export const columns = [
	bulletColumn,
	{ Header: 'Material Data', columns: materialColumns, headerClassName: styles.MetaHeader },
	{ Header: 'Savings', columns: savingsColumns, headerClassName: mergeClasses(styles.MetaHeader, styles.CurrentPlanHeader) },
	{ Header: 'Costs & other', columns: costsColumns, headerClassName: mergeClasses(styles.MetaHeader, styles.CurrentPlanHeader) },
	{ Header: 'CO2', columns: c02Columns, headerClassName: mergeClasses(styles.MetaHeader, styles.CurrentPlanHeader) },
	{ Header: 'Master data', columns: parametersColumns, headerClassName: styles.MetaHeader }
];

export default ({ data, onClick, selected, setSelected, setToggled, toggle, toggleAll, toggled, ...rest }) => {
	const onMaterialSelected = id => {
		const newSelected = _.xor(selected, [id]);
		setSelected(newSelected);
		onClick(newSelected);
	};

	return (
		<ReactTable
			{...TableDefault}
			className={mergeClasses(styles.SelectReactTable, '-hover')}
			columns={columns}
			data={data}
			filterMethod={filterWords} // Case insensitive filtering
			getTrProps={(state, { original: { id } }) => ({ onClick: () => onMaterialSelected(id) })}
			isSelected={id => selected.indexOf(id) > -1}
			selectAll={selected.length === (resolvedData || data).length}
			selectType="checkbox"
			SelectAllInputComponent={SelectInput}
			SelectInputComponent={SelectInput}
			toggleAll={() => {
				const newSelected = selected.length === resolvedData.length ? [] : resolvedData.map(m => m._original.id);
				setSelected(newSelected);
				onClick(newSelected);
			}}
			toggleSelection={(a, s, material) => onMaterialSelected(material.id)}
			{...rest}>
			{({ sortedData }, makeTable) => {
				resolvedData = sortedData;
				return <div>{makeTable()}</div>;
			}}
		</ReactTable>
	);
};
