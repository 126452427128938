// mirror tohe store
import React, { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCategories, selectCompany, selectFeatures, selectIsLogged, selectMaterials, selectUser } from 'modules/auth/selectors';

const StoreContext = createContext(null);

export const useStoreContext = () => {
	const context = useContext(StoreContext);
	if (context === null) {
		throw new Error('useStoreContext must be used within a StoreProvider');
	}
	return context;
};

export const StoreProvider = ({ children }) => {
	const isLogged = useSelector(selectIsLogged());
	const user = useSelector(selectUser());
	const materials = useSelector(selectMaterials());
	const categories = useSelector(selectCategories());
	const features = useSelector(selectFeatures());
	const company = useSelector(selectCompany());

	const store = useMemo(() => ({
		isLogged,
		user,
		materials,
		categories,
		features,
		company
	}), [isLogged, user, materials, categories, features, company]);

	return (
		<StoreContext.Provider value={store}>
			{children}
		</StoreContext.Provider>
	);
};
