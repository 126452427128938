/**
 *
 * Tooltip
 *
 */

import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import CustomIcon from '../CustomIcon';
import styles from './styles.scss';

/**
 * Sugar to create question mark with tooltip
 */

export const QuestionMark = forwardRef((props, ref) => (
	<span {...props} ref={ref}>
		<CustomIcon icon="question-mark" className={styles.QuestionMarkIcon} />
	</span>
));

export const QuestionMarkTooltip = ({ title, className }) => (
	<Tooltip title={title} className={className}>
		<QuestionMark />
	</Tooltip>
);

QuestionMarkTooltip.propTypes = { title: PropTypes.node.isRequired, className: PropTypes.string };
