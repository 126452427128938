/**
 *
 * CustomIcon
 *
 */

import PropTypes from 'prop-types';
import React from 'react';

import { mergeClasses } from 'utils/classHelper';
import styles from './styles.scss';

const CustomIcon = ({ icon, className, ...etc }) => {
	let iconEl;

	// eslint-disable-next-line default-case
	switch (icon) {
		case 'question-mark':
			iconEl = (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 125" className={styles.Svg}>
					<path d="M50 67c-1.1 0-2 0.9-2 2v4c0 1.1 0.9 2 2 2s2-0.9 2-2v-4C52 67.9 51.1 67 50 67z" />
					<path d="M40.8 34.9c0.2-0.2 0.4-0.4 0.5-0.6 1.7-1.9 4.1-4.5 9.1-4.5 5.7 0 9.2 3.7 9.2 7.1 0 3.4-1.7 5-5.5 8.1l-0.3 0.2C49.8 48.7 48 51.4 48 59c0 1.1 0.9 2 2 2s2-0.9 2-2c0-6.4 1.2-7.9 4.5-10.7l0.3-0.2c3.7-3.1 6.9-5.7 6.9-11.2 0-5.4-5-11.1-13.2-11.1 -6.9 0-10.3 3.8-12.1 5.8 -0.2 0.2-0.3 0.4-0.5 0.5 -0.8 0.8-0.7 2.1 0.1 2.8C38.8 35.7 40.1 35.7 40.8 34.9z" />
					<path d="M50 92c22.8 0 42-19.2 42-42S72.8 8 50 8 8 27.2 8 50 27.2 92 50 92zM50 12c20.6 0 38 17.4 38 38S70.6 88 50 88 12 70.6 12 50 29.4 12 50 12z" />
				</svg>
			);
			break;

		case 'dots':
			iconEl = (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 4">
					<g>
						<g>
							<circle cx="2" cy="2" r="2" />
							<circle cx="19" cy="2" r="2" />
							<circle cx="10.5" cy="2" r="2" />
						</g>
					</g>
				</svg>
			);
			break;

		case 'line':
			iconEl = (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 3" className={styles.Svg}>
					<path d="M17.5 3h-16C0.7 3 0 2.3 0 1.5v0C0 0.7 0.7 0 1.5 0h16C18.3 0 19 0.7 19 1.5v0C19 2.3 18.3 3 17.5 3z" />
				</svg>
			);
			break;

		case 'dashedLine':
			iconEl = (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 3" className={styles.Svg}>
					<line strokeDasharray="5,5" x1="0" y1="0" x2="20" y2="0" />
				</svg>
			);
			break;

		case 'hashed':
			iconEl = (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 3" className={styles.Svg}>
					<line x1="0" y1="20" x2="20" y2="0" />
					<line x1="0" y1="10" x2="10" y2="0" />
				</svg>
			);
			break;
	}

	return (
		<div className={mergeClasses(styles.CustomIcon, className)} {...etc}>
			{iconEl}
		</div>
	);
};

CustomIcon.propTypes = {
	icon: PropTypes.string.isRequired,
	className: PropTypes.string
};

export default CustomIcon;
