import _ from 'lodash';
import React from 'react';

import tableStyles from 'components/ReactTable/styles.scss';
import { frequencyToUnit } from 'utils/dates';
import { formatShortNumber } from 'utils/format';
import CalculatedIcon from './CalculatedIcon.svg';
import styles from './styles.scss';

export const boldIfEdited = ({ value, original: { edited } }) => <span className={edited ? styles.ModifiedName : ''}>{value}</span>;

export const formatDiff = (value, originalValue, { unit = '', factor = 1, decimals, calculated }) => {
	if (!_.isNumber(value)) {
		return <></>;
	}
	if (value === originalValue) {
		return (
			<>
				<div className={tableStyles.Number}>{formatShortNumber(value * factor, decimals)}</div>
				<div className={tableStyles.Unit}>{unit}</div>
			</>
		);
	}
	return (
		<div className={styles.Modified}>
			<div>{formatShortNumber(originalValue * factor, decimals)}&nbsp;</div>
			{/* <div className={calculated ? styles.Calculated : ''}> */}
			{/*		<span title="Computed based on other parameters"> */}
			{/*			<img src={CalculatedIcon} alt="Calculated field" /> */}
			{/*			{formatShortNumber(value * factor, decimals)} */}
			{/*		</span> */}
			{/* </div> */}
			<div>
				<div className={calculated ? styles.Calculated : ''}>
					{/* <div className={styles.CalculatedIcon}> */}
					{calculated && (
						<>
							{/* <Tooltip tip="This value was calculated from the other parameters"> */}
							<img src={CalculatedIcon} alt="Calculated field" />
							{/* </Tooltip> */}
						</>
					)}
					{/* </div> */}
					{formatShortNumber(value * factor, decimals)}
				</div>
				<div className={tableStyles.Unit}>{unit}</div>
			</div>
		</div>
	);
};
export const cellWithDiff = ({ value, original: { base }, column: { id } }, config) => formatDiff(value, base[id], config);
export const withDiff = unit => d => cellWithDiff(d, { unit });
export const unitWithDiff = (row, config) => cellWithDiff(row, { ...config, unit: row.original.unit });
export const currencyWithDiff = (row, config) => cellWithDiff(row, { ...config, unit: row.original.currency });
export const frequencyWithDiff = (row, config) => cellWithDiff(row, { ...config, unit: frequencyToUnit(row.original.frequency) });
export const percentWithDiff = (row, config) => cellWithDiff(row, { ...config, unit: '%', factor: 100, decimals: 2 });

export const stringWithDiff = ({ value, original: { base }, column: { id } }) => {
	const originalValue = base[id];
	if (value === originalValue) {
		return (
			<>
				<div className={tableStyles.Number}>{value}</div>
				<div className={tableStyles.Unit} />
			</>
		);
	}
	return (
		<div className={styles.Modified}>
			<div>{originalValue}&nbsp;</div>
			<div>
				<div>{value}</div>
				<div className={tableStyles.Unit} />
			</div>
		</div>
	);
};

/*
 * Higher order cells
 */

export const ifEqualElse = (columnName, value) => (cellIfTrue, cellIfFalse) => (row, config) =>
	row.original[columnName] === value ? cellIfTrue(row, config) : cellIfFalse(row, config);

export const emptyCell = () => <></>;
export const emptyIfNotEqual = (columnName, value) => cellFn => ifEqualElse(columnName, value)(cellFn, emptyCell);

// Add calculated config parameter to a cellFn
export const calculated = cellFn => (row, config) => cellFn(row, { ...config, calculated: true });
