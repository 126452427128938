import { addBase, getEditedColumns } from '../SimulatorEditionPage/preprocessing';
import { defaultColumns, defaultMaterialColumns } from './Table';
import { materialsEqual } from 'modules/scenario';

export const preProcessComparison = (comparison, filterEdited) => {
	if (!comparison || !comparison.materials) return comparison;

	let editedMaterials = comparison.materials
		.filter(m => !materialsEqual(m, comparison.scenarios[0].materials.find(({ number }) => number === m.number)))
		.map(m => m.number);

	let augmentedComparison = addBase(comparison);
	augmentedComparison = {
		...augmentedComparison,
		editedMaterials,
		editedColumns: comparison.scenarios ? [...defaultMaterialColumns, ...getEditedColumns(augmentedComparison)] : defaultColumns,
	};

	if (filterEdited) {
		augmentedComparison = {
			...augmentedComparison,
			editedMaterials,
			materials: augmentedComparison.materials.filter(m => editedMaterials.includes(m.number)),
			unfilteredMaterials: augmentedComparison.materials,
			scenarios: augmentedComparison.scenarios.map(({ materials: scenarioMaterials, ...restOfScenario }) => ({
				materials: scenarioMaterials.filter(m => editedMaterials.includes(m.number)),
				...restOfScenario
			}))
		};
	}

	if (!augmentedComparison || !augmentedComparison.materials) return { augmentedComparison };

	const { materials, scenarios, ...restOfComparison } = augmentedComparison;

	const numbers = augmentedComparison.materials.filter(m => m.calculation)
		.filter(m => augmentedComparison.scenarios.every(s => s.materials.some(sm => sm.number === m.number && sm.calculation)))
		.map(m => m.number);

	const filter = mats => mats.filter(m => numbers.includes(m.number));
	const failed = materials.filter(m => !numbers.includes(m.number));

	return {
		...restOfComparison,
		materials: filter(materials),
		failedMaterials: failed.length,
		scenarios: scenarios.map(({ materials: sMaterials, ...sRest }) => ({
			...sRest,
			materials: filter(sMaterials)
		}))
	};
};
