import React, { useEffect, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import styles from './styles.scss';

export default function InlineEdit({ value = '', onChange, onBlur }) {
	const [val, setVal] = useState('');
	useEffect(() => {
		setVal(value || 'Untitled');
	}, [value]);
	return (
		<TextField
			className={styles.Title}
			value={val}
			fullWidth
			onChange={e => {
				setVal(e.target.value);
				if (typeof onChange === 'function') onChange(e.target.value);
			}}
			onFocus={event => event.target.select()}
			onBlur={() => {
				if (typeof onBlur === 'function') {
					onBlur(val);
				}
			}}
			onClick={e => {
				e.stopPropagation();
				e.preventDefault();
			}}
			InputProps={{
				endAdornment: (
					<InputAdornment position="start">
						<EditIcon color="action" />
					</InputAdornment>
				)
			}}
		/>
	);
}
