// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-CustomIcon-styles-CustomIcon{display:inline-block;line-height:0;font-size:0}.app-components-CustomIcon-styles-CustomIcon .app-components-CustomIcon-styles-Svg{display:inline-block}.app-components-CustomIcon-styles-CustomIcon .app-components-CustomIcon-styles-Svg:nth-child(even){clear:right}", "",{"version":3,"sources":["webpack://./app/components/CustomIcon/styles.scss"],"names":[],"mappings":"AACA,6CACC,oBAAA,CACA,aAAA,CACA,WAAA,CAEA,mFACC,oBAAA,CAEA,mGACC,WAAA","sourcesContent":["@import \"style/variables.scss\";\n.CustomIcon {\n\tdisplay: inline-block;\n\tline-height: 0;\n\tfont-size: 0;\n\n\t.Svg {\n\t\tdisplay: inline-block;\n\n\t\t&:nth-child(even) {\n\t\t\tclear: right;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CustomIcon": "app-components-CustomIcon-styles-CustomIcon",
	"Svg": "app-components-CustomIcon-styles-Svg"
};
export default ___CSS_LOADER_EXPORT___;
