import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import _ from 'lodash';
import React, { useState } from 'react';

import { Checkbox } from './ColumnMenu';
import styles from './styles.scss';

export default function MultiColumnMenu({ button, columns, title, reset, onSelect, onSelectAll, ...rest }) {
	const [anchorEl, setAnchorEl] = useState(null);

	// Group elements by category
	const categories = {};
	const allColumns = [];
	columns.forEach(metaColumn => {
		metaColumn.columns &&
			metaColumn.Header &&
			metaColumn.columns.forEach(c => {
				if (!c.noMenu) {
					const cat = metaColumn.Header;
					if (!categories[cat]) categories[cat] = [];
					categories[cat].push(c);
					allColumns.push(c);
				}
			});
	});

	return (
		<>
			<Button startIcon={<ViewColumnIcon />} endIcon={<ExpandMoreIcon />} onClick={event => setAnchorEl(event.currentTarget)} {...rest} />
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				className={styles.Menu}
				onClose={() => setAnchorEl(null)}
				getContentAnchorEl={null}>
				<Grid container>
					{_.map(categories, (cat, name) => (
						<Grid item key={name}>
							<MenuList>
								{cat == _.first(_.values(categories)) && (
									<MenuItem onClick={() => onSelectAll(allColumns)} style={{ fontWeight: 600 }}>
										<Checkbox checked={!allColumns.find(i => !i.show)} label="All" />
									</MenuItem>
								)}
								{cat == _.last(_.values(categories)) && (
									<MenuItem button onClick={reset}>
										<SettingsBackupRestoreIcon /> Reset
									</MenuItem>
								)}
								{cat != _.last(_.values(categories)) && cat != _.first(_.values(categories)) && <MenuItem disabled>&nbsp;</MenuItem>}
								<MenuItem onClick={() => onSelectAll(cat)} style={{ fontWeight: 600 }}>
									<Checkbox checked={!cat.find(i => !i.show)} label={name} />
								</MenuItem>
								{cat.map(i => (
									<MenuItem key={i.accessor || i.id} onClick={() => onSelect(i)}>
										<Checkbox checked={i.show} label={i.Header} />
									</MenuItem>
								))}
							</MenuList>
						</Grid>
					))}
				</Grid>
			</Popover>
		</>
	);
}
