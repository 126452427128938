// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-Tooltip-styles-QuestionMarkIcon{vertical-align:middle;width:15px;height:19px}", "",{"version":3,"sources":["webpack://./app/components/Tooltip/styles.scss"],"names":[],"mappings":"AACA,gDACC,qBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":["@import \"style/variables.scss\";\n.QuestionMarkIcon {\n\tvertical-align: middle;\n\twidth: 15px;\n\theight: 19px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QuestionMarkIcon": "app-components-Tooltip-styles-QuestionMarkIcon"
};
export default ___CSS_LOADER_EXPORT___;
