import XLSX from 'xlsx';
import { trim } from 'utils/string';
import { columnKey } from './index';

export const downloadTableXLSX = (data, name, config) => {

	let columnsToExport = config?.onlyVisible ? data.allVisibleColumns : data.allDecoratedColumns;
	const withOriginals = config?.withOriginals || []

	columnsToExport = columnsToExport.map(col => {
		// Handle cases where the header is a React element due to tooltips
		// Also add a flag to indicate if the column has an original value
		return {
			...col,
			exportHeader: typeof col.Header === 'object' ? col.Header.props.label : col.Header,
			withOriginal: withOriginals.some(original => original.id === col.id)
		}
	})
		.filter(col => col.export !== false && col.exportHeader);

	if (config && config.columns) {
		columnsToExport = columnsToExport.filter(col => config.columns.includes(columnKey(col)));
	}

	const rows = data.resolvedData.map(row => {
		const rowValues = {};
		columnsToExport.forEach(col => {
			let value = row[col.id];
			// Convert values to comma-separated strings if they are arrays
			if (Array.isArray(value)) value = value.map(i => i.name).join(', ');
			rowValues[col.exportHeader] = value;
			if (col.withOriginal) {
				// In case of simulation we can export the original values as well
				rowValues[`Original ${col.exportHeader}`] = data.data.find(m => m.id === row._original.id)?.base[col.id];
			}
		});
		return rowValues;
	});
	console.log('rows', rows);

	const workbook = XLSX.utils.book_new();
	const worksheet = XLSX.utils.json_to_sheet(rows);

	// Adjust columns width
	worksheet['!cols'] = columnsToExport
		.map(col => rows.reduce((acc, row) => Math.max(acc, `${row[col.exportHeader]}`.length), col.exportHeader.length))
		.map(i => ({ wch: Math.min(i, 30) }));


	XLSX.utils.book_append_sheet(workbook, worksheet, trim(name, 31));
	XLSX.writeFile(workbook, `${name}.xlsx`);
};
