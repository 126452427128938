import XLSX from 'xlsx';
import logger from 'utils/logger';
import React from 'react';
import { toast } from 'react-toastify';

export const bulkUpdate = ({ comparison, updateScenario }) => (e, materials) => {
	return new Promise((resolve, reject) => {
		const { files } = e.target;
		const file = files[0];
		if (!file.name.endsWith('.xls') && !file.name.endsWith('.xlsx')) {
			toast.error('Invalid file format. Please use .xls or .xlsx files.');
			reject(new Error('Invalid file format'));
		} else {
			const reader = new FileReader();
			reader.onload = async (e2) => {
				try {
					const data = new Uint8Array(e2.target.result);
					const wb = XLSX.read(data, { type: 'array' });
					const ws = wb.Sheets[wb.SheetNames[0]];
					const jsonData = XLSX.utils.sheet_to_json(ws, { header: 1, strip: true, skipHidden: true, blankrows: false });
					const headers = jsonData[0].slice(1);
					const lines = jsonData.slice(1);

					const materialsToUpdate = [];
					const materialsNotFound = [];

					lines.forEach(l => {
						const material = materials.find(m => m.number === l[0]);
						if (material) {
							const ret = { id: material.id };
							headers.forEach((f, index) => {
								const value = l[index + 1];
								if (value !== undefined) {
									ret[f] = value;
								}
							});
							materialsToUpdate.push(ret);
						} else {
							materialsNotFound.push(l[0]);
						}
					});

					await updateScenario(comparison.scenarios[0].id, materialsToUpdate);

					if (materialsNotFound.length > 0) {
						toast.error(<><strong>{`${materialsNotFound.length} of ${materialsToUpdate.length + materialsNotFound.length}`} materials not
							found:</strong> {materialsNotFound.join(', ')}</>);
					} else {
						toast.success(`Imported ${materialsToUpdate.length} from file`);
					}

					resolve();
				} catch (err) {
					logger.error(err);
					toast.error('Error importing XLS file');
					reject(err);
				}
			};

			reader.onerror = (error) => {
				logger.error(error);
				toast.error('Error reading file');
				reject(error);
			};

			reader.readAsArrayBuffer(file);
		}
		e.target.value = '';
	});
};
