import _ from 'lodash';

export function filterNumbers({ id, value }, row) {
	return row[id] === +value;
}

// Case insensitive filtering
export function filterWords({ id, value }, row) {
	if (!value) return true;
	if (!row[id]) return false;
	return (
		row[id]
			.toString()
			.trim()
			.toLowerCase()
			.indexOf(value.toLowerCase()) > -1
	);
}

export function filterSuppliers({ value }, { suppliers }) {
	if (!value) return true;
	if (!suppliers) return false;
	let keep = false;
	_.forEach(suppliers, s => {
		if (s.name.toLowerCase().indexOf(value.toLowerCase()) > -1) keep = true;
	});
	return keep;
}
