import { Controller } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import React from 'react';

export const MaterialCheckbox = ({ control, name, label, className, defaultValue=false, ...etc }) => (
	<Controller
		control={control}
		name={name}
		defaultValue={defaultValue}
		render={({ field }) => (
			<FormControlLabel
				className={className}
				label={label}
				control={<Checkbox checked={field.value} onChange={(e) => field.onChange(e.target.checked)} {...etc} />}
			/>
		)}
	/>
);

MaterialCheckbox.propTypes = {
	control: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	className: PropTypes.string,
	defaultValue: PropTypes.bool }
