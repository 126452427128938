const logger = {
	error: (...params) => console.error(...params),
	info: (...params) => {
		// if (process.env.NODE_ENV !== 'production') {
		console.log(...params);
		// }
	},
	groupLog: (type, ...etc) => {
		console.group(type);
		etc.map(e => e && console.log(e));
		console.groupEnd();
	}
};

export default logger;
