import PropTypes from 'prop-types';
import React, { createElement, memo } from 'react';
import { mergeClasses } from 'utils/classHelper';
import styles from './styles.scss';

export const Text = ({
	domElement = 'span',
	size = 'text',
	weight = 'regular', // bold || regular || light
	color = 'limedSpruce',
	textStyle = null, // uppercase || lowercase || initial
	htmlFor = null, // if domElement === Label
	className = '', // extend className
	typo,
	children,
	onClick
}) => {
	const textSize = styles[size];
	const textColor = styles[color];
	const textWeight = styles[weight];
	const textTypo = styles[typo];
	const textStyleTransform = textStyle ? styles[textStyle] : null;

	const domElementProps = {};
	if (htmlFor) {
		domElementProps.htmlFor = htmlFor;
	}

	return (
		<div className={mergeClasses(styles.Text, className, textSize, textColor, textWeight, textTypo, textStyleTransform)} onClick={onClick}>
			{createElement(domElement, domElementProps, children)}
		</div>
	);
};

Text.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string]),
	domElement: PropTypes.string,
	size: PropTypes.string,
	weight: PropTypes.string,
	color: PropTypes.string,
	textStyle: PropTypes.string,
	htmlFor: PropTypes.string,
	className: PropTypes.string,
	typo: PropTypes.string,
	onClick: PropTypes.func
};

export default memo(Text);
