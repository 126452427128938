/**
 *
 * ResultsWaterfall
 *
 */

import Grid from '@material-ui/core/Grid';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import ArrowMid from '@material-ui/icons/UnfoldLess';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { mergeClasses } from 'utils/classHelper';
import { daysToHorizon, frequencyToUnit } from 'utils/dates';
import { formatInteger, formatQuantity, formatRoundedCurrency, formatShortNumber, percentDiff } from 'utils/format';
import styles from './styles.scss';

/* eslint no-param-reassign: 0 */
const WaterfallChart = ({ material }) => {
	const data = material.calculation;
	const unit = material.currency;
	const B = material.base.calculation;
	// Columns definitions
	const columns = [
		{ value: B.SupplyChainCost },
		// { value: data.quantityDiscounts },
		{ value: data.orderSavings },
		{ value: data.inventorySavings },
		{ value: data.scrapSavings },
		{ value: data.SupplyChainCost }
	];
	const midColumns = columns.slice(1, columns.length - 1);
	const endColumns = [columns[0], columns[columns.length - 1]];

	// Compute columns start, height and end based on previous columns
	endColumns.forEach(col => (col.end = col.height = col.value)); // eslint-disable-line no-return-assign, no-multi-assign
	midColumns.forEach((col, i) => {
		col.start = columns[i].end;
		col.end = col.start - col.value;
		col.height = col.value;
	});

	// Reorder start and end so end is always above start
	midColumns.forEach(col => {
		if (col.start > col.end) {
			const tmp = col.start;
			col.start = col.end;
			col.end = tmp;
		}
	});

	// Calculate max height
	const max = _.maxBy(columns, 'end').end;
	const min = _.minBy(midColumns, 'start').start;
	const diff = max - min;
	const height = 280 / 2; // Graph will be 280px

	// Scale columns to fit graph
	midColumns.forEach(col => {
		if (diff !== 0) {
			col.start = (col.start - min) / diff + 1;
			col.height /= diff;
		} else {
			col.start = 2;
		}
	});
	endColumns.forEach(col => {
		col.start = 0;
		if (diff !== 0) {
			col.height = (col.height - min) / diff + 1;
		} else {
			col.height = 2;
		}
	});

	// Create some ad hoc components
	const triCond = (value, below, above, equal, precision = 0) => {
		const v = _.round(value, precision);
		return v > 0 ? above : v < 0 ? below : equal; // eslint-disable-line no-nested-ternary, no-confusing-arrow
	};
	const Title = ({ children, ...props }) => (
		<Text color="shuttleGray" size="bigger" weight="bold" {...props}>
			{children}
			<Spacer />
		</Text>
	);
	const BigText = props => <Text color="shuttleGray" size="big" {...props} />;
	const SubTitle = props => <Text color="shuttleGray" size="big" className={styles.Subtitle} {...props} />;
	const Currency = props => (
		<span className={styles.Currency} {...props}>
			{material.currency}
		</span>
	);

	const Badge = ({ value }) =>
		value === 0 ? (
			<div />
		) : (
			<div className={mergeClasses(styles.Badge, triCond(value, styles.Green, styles.Red, null, 1))}>
				{triCond(value, <ArrowDown />, <ArrowUp />, <ArrowMid />, 1)}
				{formatShortNumber(Math.abs(value), 1)}%
			</div>
		);
	const customQuantity = v => (v > 1 ? formatInteger(v) : formatQuantity(v));

	const horizon = daysToHorizon(material.horizon, material.frequency);
	const horizonUnit = frequencyToUnit(material.frequency);
	const influencedHorizon = horizon - daysToHorizon(material.supplierLeadTime || 0, material.frequency);
	// Inject custom quantity discount last column
	const qdHeight = data.quantityDiscounts / diff;
	const qdColumn = {
		start: columns[4].start + columns[4].height - Math.max(qdHeight, 0),
		height: qdHeight,
		value: data.quantityDiscounts
	};
	if (qdColumn.start < 0.2) {
		qdColumn.start = 0.2;
		qdColumn.height = columns[4].start + columns[4].height - 0.2;
	}
	if (qdColumn.start + Math.abs(qdColumn.height) > 3) {
		qdColumn.height = 3 - qdColumn.start;
	}
	columns.push(qdColumn);
	let finalHeight = height * (qdColumn.start + Math.abs(qdColumn.height)); // Hack
	if (finalHeight === Infinity || Number.isNaN(finalHeight)) finalHeight = height * 2;
	finalHeight = Math.max(finalHeight, height * 2);

	const rGenLotsAveragePrice = _.round(data.AveragePrice, 4);
	const rComparisonAveragePrice = _.round(B.AveragePrice, 4);
	const showAveragePriceComparison =
		rGenLotsAveragePrice !== 0 && rComparisonAveragePrice !== 0 && rGenLotsAveragePrice !== rComparisonAveragePrice;

	return (
		<div>
			<div className={styles.footer}>
				<Grid container justifyContent="center" spacing={5}>
					<Grid item>
						Inventory: {formatRoundedCurrency(B.AveragePositiveInventory * material.accountingPrice)} {unit}
					</Grid>
					<Grid item>
						Spend: {formatRoundedCurrency(B.SumPurchasingCost)} {unit}
					</Grid>
					<Grid item>
						Analyzed horizon: {horizon} {horizonUnit}
					</Grid>
					<Grid item>
						Influenced horizon: {influencedHorizon} {horizonUnit}
					</Grid>
				</Grid>
			</div>
			<div className={styles.Body}>
				<div className={styles.ResultsWaterfall}>
					{/* Bars */}
					<div className={styles.Bars} style={{ height: `${finalHeight}px` }}>
						{/* <div className={styles.Bars} style={{ height: `${2 * height}px` }}> */}
						{columns.map((col, i) => (
							<div key={i} className={triCond(col.value, styles.Red, styles.Green)}>
								<div style={{ bottom: `${height * col.start}px`, height: `${height * Math.abs(col.height)}px` }}>
									{/* Label */}
									<div className={styles.Label}>
										{triCond(col.value, <ArrowUp />, <ArrowDown />, <ArrowMid />)}
										{formatRoundedCurrency(Math.abs(col.value))} <Currency />
									</div>
									<div className={styles.LineUp} />
									<div className={styles.LineDown} />
								</div>
							</div>
						))}
					</div>

					{/* Legend line 1 */}
					<div className={styles.Legend}>
						<div>
							<Title className={styles.Total}>
								{formatRoundedCurrency(B.SupplyChainCost)} <Currency />
							</Title>
							<SubTitle>Supply chain cost with current orders</SubTitle>
						</div>
						<div>
							<BigText weight="bold">
								Orders
								<Spacer />
							</BigText>
							<BigText>
								<div className={styles.gray}>
									<b>{data.OrdersCount}</b> instead of <b>{B.OrdersCount}</b>
									<br />
								</div>
							</BigText>
							<Badge value={percentDiff(data.OrdersCount, B.OrdersCount)} />
						</div>
						<div>
							<BigText weight="bold">
								Inventory
								<Spacer />
							</BigText>
							{/* <BigText className={styles.gray}>Average inventory</BigText> */}
							<BigText className={styles.gray}>
								<b>
									{customQuantity(data.AveragePositiveInventory)} {material.unit || 'kg'}
								</b>{' '}
								instead of{' '}
								<b>
									{customQuantity(B.AveragePositiveInventory)} {material.unit || 'kg'}
								</b>{' '}
								on average
							</BigText>
							<Badge value={percentDiff(data.AveragePositiveInventory, B.AveragePositiveInventory)} />
						</div>
						<div>
							<BigText weight="bold">
								Scrap
								<Spacer />
							</BigText>
							<BigText className={styles.gray}>
								<b>
									{formatRoundedCurrency(data.SumScrapCost)} {unit}
								</b>{' '}
								instead of{' '}
								<b>
									{formatRoundedCurrency(B.SumScrapCost)} {unit}
								</b>
							</BigText>
							<Badge value={percentDiff(data.SumScrapCost, B.SumScrapCost)} />
						</div>
						<div>
							<Title color="blueViolet" className={styles.Total}>
								{formatRoundedCurrency(data.SupplyChainCost, 1)} <Currency />
							</Title>
							<SubTitle>Supply chain cost with GenLots</SubTitle>
						</div>
						<div>
							<BigText weight="bold">
								Quantity discounts
								<Spacer />
							</BigText>
							<div>
								<BigText className={styles.gray}>
									average price of{' '}
									<b>
										{formatShortNumber(data.AveragePrice, 4)} {unit}
									</b>{' '}
									{showAveragePriceComparison && (
										<>
											instead of{' '}
											<b>
												{formatShortNumber(B.AveragePrice, 4)} {unit}
											</b>
										</>
									)}
								</BigText>
								{showAveragePriceComparison && <Badge value={percentDiff(data.AveragePrice, B.AveragePrice)} />}
							</div>
						</div>
					</div>

					{/* Legend line 2 */}
					<Grid container className={styles.Legend2}>
						<Grid item className={triCond(data.supplyChainSavings, styles.Red, styles.Green)}>
							<Title color={triCond(data.supplyChainSavings, 'red', 'green')}>
								{formatRoundedCurrency(Math.abs(data.supplyChainSavings))} <Currency />
							</Title>
							<BigText className={styles.bigger}>Supply chain savings</BigText>
							<BigText>
								<Badge value={percentDiff(data.SupplyChainCost, B.SupplyChainCost)} />
							</BigText>
						</Grid>
						<Grid item className={triCond(data.quantityDiscounts, styles.Red, styles.Green)}>
							<Title color={triCond(data.quantityDiscounts, 'red', 'green')}>
								{formatRoundedCurrency(Math.abs(data.quantityDiscounts))} <Currency />
							</Title>
							<BigText className={styles.bigger}>Purchasing savings</BigText>
							{/* <BigText> */}
							{/*	<Badge value={-data.pureSavingsPercent} /> */}
							{/* </BigText> */}
						</Grid>
					</Grid>
				</div>

				<div className={styles.LeftLegend}>
					<div
						className={triCond(data.pureSavings, styles.Red, styles.Green)}
						style={{
							height: `${Math.abs(columns[0].start + columns[0].height - qdColumn.start) * (finalHeight - height)}px`
						}}>
						<Title color={triCond(data.pureSavings, 'red', 'green')}>
							{formatRoundedCurrency(Math.abs(data.pureSavings))} <Currency />
						</Title>
						<BigText className={styles.biggest}>Total savings</BigText>
					</div>
				</div>
			</div>
		</div>
	);
};

WaterfallChart.propTypes = {
	material: PropTypes.object.isRequired
};

export default WaterfallChart;
