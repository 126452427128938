import * as d3 from 'd3';
import _ from 'lodash';

import { formatNumber } from 'utils/format';

export const updateRegions = () => {
	const regions = d3.selectAll('.c3-region')._groups[0]; // eslint-disable-line no-underscore-dangle
	regions.forEach(regionElement => {
		const region = d3.select(regionElement);
		const rectangle = region.select('rect');
		rectangle.attr('width', region.attr('width'));
		// .transition()
		// .duration(0)
		rectangle.attr('x', region.attr('x'));
		// .transition()
		// .duration(0)
	});
};

export const updateTicks = _.throttle((domain, chart) => {
	if (!chart || !chart.element) return;
	const ticks = Array.from(document.querySelectorAll('.c3-axis-x.c3-axis > g'));
	const maxTicks = chart.element.clientWidth / 50;
	if (ticks.length > maxTicks) {
		const level = Math.ceil(domain[1] - domain[0]);
		let fact = 1;
		if (level < 12) {
			fact = 4;
		} else if (level < 30) {
			fact = 2;
		}
		const every = Math.ceil(ticks.length / maxTicks / fact);
		ticks.forEach((t, i) => (t.querySelector('text').style.display = (i % every) / fact === 0 ? 'block' : 'none'));
	}

	// Update regions
	updateRegions();
}, 10);

export default {
	bar: { width: { ratio: 0.9 } },
	data: {
		order: null,
		names: {
			genLotsSafetyStock: 'GenLots safety stock',
			comparisonAvailableInventory: 'SAP available inventory',
			comparisonDeliveredInventory: 'SAP delivered inventory',
			comparisonDeliveries: 'SAP order',
			comparisonSafetyStock: 'SAP safety stock',
			comparisonScrap: 'SAP scrap',
			comparisonShiftedInventory: 'SAP shifted avail.inventory',
			consumptions: 'Consumption',
			fixedOrderDeliveries: 'Fixed orders',
			genLotsAvailableInventory: 'GenLots available inventory',
			genLotsDeliveredInventory: 'GenLots delivered inventory',
			genLotsDeliveries: 'GenLots order',
			genLotsScrap: 'GenLots scrap',
			genLotsShiftedInventory: 'GenLots shifted avail. inventory ',
			qmLotDeliveries: 'QM Lot Deliveries',
			safetyStock: 'Safety stock',
			maxInventory: 'Max. inventory',
			genLotsMaxInventory: 'GenLots max. inventory',
			comparisonMaxInventory: 'SAP max. inventory',
			capacity: 'Capacity',
			comparisonInventory: 'SAP available inventory',
			comparisonTotalInventory: 'SAP Total inventory',
			genLotsInventory: 'GenLots available inventory',
			genLotsTotalInventory: 'GenLots total inventory'
		},
		colors: {
			genLotsSafetyStock: '#00C2AF',
			comparisonSafetyStock: '#C0C0C0',
			fixedOrderDeliveries: '#56646E',
			qmLotDeliveries: '#000000',
			consumptions: '#00C2AF',
			consumptions2: '#00C2AF',
			consumptions3: '#00C2AF',
			genLotsDeliveries: '#5B63B6',
			genLotsScrap: '#5B63B6',
			genLotsAvailableInventory: '#5B63B6',
			genLotsDeliveredInventory: '#5B63B6',
			genLotsShiftedInventory: '#5B63B6',
			comparisonDeliveries: '#C0C0C0',
			comparisonScrap: '#C0C0C0',
			comparisonAvailableInventory: '#C0C0C0',
			comparisonDeliveredInventory: '#C0C0C0',
			comparisonShiftedInventory: '#C0C0C0',
			capacity: '#E44600',
			safetyStock: '#00C2AF',
			maxInventory: '#E44600',
			genLotsMaxInventory: '#E44600',
			comparisonMaxInventory: '#825150',
			genLotsInventory: '#5B63B6',
			genLotsTotalInventory: '#5B63B6',
			comparisonInventory: '#C0C0C0',
			comparisonTotalInventory: '#C0C0C0'
		},
		types: {
			genLotsSafetyStock: 'line',
			faker: 'line',
			faker2: 'line',
			capacity: 'line',
			safetyStock: 'line',
			maxInventory: 'line',
			genLotsMaxInventory: 'line',
			comparisonMaxInventory: 'line',
			comparisonSafetyStock: 'line',
			fixedOrderDeliveries: 'bar',
			qmLotDeliveries: 'bar',
			consumptions: 'bar',
			consumptions2: 'bar',
			consumptions3: 'bar',
			genLotsDeliveries: 'bar',
			genLotsScrap: 'bar',
			genLotsAvailableInventory: 'line',
			genLotsDeliveredInventory: 'line',
			genLotsInventory: 'line',
			genLotsShiftedInventory: 'line',
			genLotsTotalInventory: 'line',
			comparisonDeliveries: 'bar',
			comparisonScrap: 'bar',
			comparisonAvailableInventory: 'line',
			comparisonDeliveredInventory: 'line',
			comparisonInventory: 'line',
			comparisonShiftedInventory: 'line',
			comparisonTotalInventory: 'line'
		},
		groups: [
			['consumptions', 'qmLotDeliveries', 'fixedOrderDeliveries'],
			['consumptions2', 'genLotsDeliveries', 'genLotsScrap'],
			['consumptions3', 'comparisonDeliveries', 'comparisonScrap']
		]
	},
	grid: {
		x: { show: false },
		y: { show: true, lines: [{ value: 0, class: 'base-line' }] }
	},
	hide: ['genLotsShiftedInventory', 'comparisonShiftedInventory'],
	interaction: { enabled: true },
	legend: { show: false, position: 'inset', inset: { anchor: 'top-left', x: 0, y: -60, step: 2 } },
	oninit() {
		setTimeout(() => updateTicks(this.api.zoom(), this.api), 10);
	},
	onrendered() {
		// Dotted x-axis line
		this.axes.x.selectAll('.tick line').attr('y2', 1);
		// #updateRegions();
		updateTicks(this.api.zoom(), this.api);
	},
	onresized() {
		setTimeout(updateRegions, 100);
	},
	padding: { left: 100, top: 10, right: 100 },
	point: { show: false },
	selection: { enabled: true },
	// size: { height: 760 },
	subchart: { show: true },
	tooltip: {
		format: { value: val => (val ? formatNumber(val) : undefined) }
	},
	transition: { duration: 0 },
	x: { tick: { centered: true } },
	zoom: {
		// initialRange: [0, 17],
		enabled: true
		// onzoomend(domain) {
		// updateTicks(domain, this);
		// }
		// rescale: true
		// type: 'drag'
	}
};
