import { CALL_API, createRequestTypes } from 'modules/api';
import { getSearchParameter } from 'utils/url';

export const LOGIN_USER = createRequestTypes('LOGIN_USER');
export const loginUser = body => ({
	type: CALL_API,
	endpoint: '/login2',
	method: 'POST',
	callType: LOGIN_USER,
	body
});

export const GET_USER = createRequestTypes('GET_USER');
export const getUser = company => ({
	type: CALL_API,
	endpoint: `/company/${company || getSearchParameter('company') || 'me'}`,
	method: 'GET',
	callType: GET_USER
});

export const LOGOUT_USER = createRequestTypes('LOGOUT_USER');
export const logout = () => ({
	type: CALL_API,
	endpoint: '/logout2',
	method: 'GET',
	callType: LOGOUT_USER
});

export const RECOVER_USER = createRequestTypes('RECOVER_USER');
export const recoverUser = body => ({
	type: CALL_API,
	endpoint: '/reset',
	method: 'POST',
	callType: RECOVER_USER,
	body
});

export const RESET_USER = createRequestTypes('RESET_USER');
export const resetUser = body => {
	const token = new URLSearchParams(window.location.search).get('token');
	return {
		type: CALL_API,
		endpoint: `/other-reset/${token}`,
		method: 'POST',
		callType: RESET_USER,
		body
	};
};
