import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { injectIntl } from 'react-intl';
import { MaterialSelectField } from 'components/input/MaterialSelectField';
import { MaterialNumberField } from 'components/input/MaterialTextField';
import { MaterialCheckbox } from 'components/input/MaterialCheckbox';
import ColumnMenu from 'components/Menu/ColumnMenu';
import { columnKey } from 'components/ReactTable';
import { QuestionMarkTooltip } from 'components/Tooltip';
import { Material } from 'modules/material';
import { frequencyToUnit } from 'utils/dates';
import { parametersColumns } from '../Table';
import styles from './styles.scss';

function Panel({ count, material, open, onCancel, onSave, toggle, toggleAll, toggled }) {
	const newMaterial = Object.fromEntries(
		Object.entries(material).map(
			([key, value]) => [key, value === '(mixed)' ? null : value])
	);

	const { handleSubmit, control, formState } = useForm({ defaultValues: newMaterial });
	const [dirtyFields, setDirtyFields] = useState({});

	useEffect(() => {
		setDirtyFields(formState.dirtyFields);
	}, [formState.dirtyFields]);

	const onSubmit = (data) => {
		onSave(data, { touched: dirtyFields });
	};

	const isMixed = v => v === '(mixed)';
	const shownColumns = parametersColumns.map(m => ({ ...m, show: toggled.includes(columnKey(m)) }));

	const fieldsConfig = [
		{ name: 'roundingValue' }, { name: 'minimumOrderQuantity' }, { name: 'maximumOrderQuantity' },
		{ name: 'fixedOrderSize' }, { name: 'safetyStock' }, { name: 'serviceLevel', factor: 100 },
		{ name: 'safetyTime' }, { name: 'maxInventory' }, { name: 'coverageDays' },
		{ name: 'supplierLeadTime' }, { name: 'qualityControlLeadTime' },
		{ name: 'strictAboutSafetyStock', type: 'boolean' }, { name: 'shelfLife' },
		{ name: 'endOfYear', type: 'boolean' }, { name: 'horizon' },
		{ name: 'pricePerUnitSingle', unit: material.currency },
		{ name: 'orderCost', unit: material.currency }, { name: 'carryingCost', factor: 100 },
		{ name: 'orderCostWeight' }, { name: 'carryingCostWeight' },
		{ name: 'maxCoverage', unit: frequencyToUnit(material.frequency) },
		{ name: 'strictMaxCoverage', type: 'boolean' }, { name: 'transportMode', type: 'select' },
		{ name: 'warehouse', type: 'select' }];


	return (<Drawer anchor="right" className={styles.Drawer} open={open} variant="persistent">
		<Grid container className={styles.Title}>
			<Grid item>
				<div>{count} materials selected</div>
			</Grid>
			<Grid>
				<IconButton onClick={onCancel}>
					<CloseIcon />
				</IconButton>
			</Grid>
		</Grid>
		<div className={styles.ResetAll}>
			<ColumnMenu title="Parameters" button columns={shownColumns} onSelect={toggle} onSelectAll={toggleAll} />
		</div>
		<form noValidate onSubmit={handleSubmit(onSubmit)}>
			<div className={styles.Body}>
				{fieldsConfig.map((field) => (<CustomField
					key={field.name}
					control={control}
					name={field.name}
					mixed={isMixed(material[field.name])}
					toggled={toggled}
					unit={field.unit}
					factor={field.factor}
					type={field.type}
				/>))}
			</div>
			<div className={styles.Buttons}>
				<ButtonGroup size="large">
					<Button color="primary" startIcon={<CheckIcon />} type="submit" variant="contained">
						Save
					</Button>
					<Button onClick={onCancel} startIcon={<ClearIcon />}>
						Cancel
					</Button>
				</ButtonGroup>
			</div>
		</form>
	</Drawer>);
}

const CustomField = injectIntl(({ intl, control, name, mixed, toggled, unit, factor, type }) => {
	if (!toggled.includes(name)) return null;

	const fieldConfig = Material[name];
	const tooltip = fieldConfig.tooltip ? intl.formatMessage(fieldConfig.tooltip) : null;

	if (type === 'boolean') {
		return (<div className={styles.Field}>
			<div>
				{fieldConfig.label} {tooltip && <QuestionMarkTooltip title={tooltip} />}
			</div>
			<div className={styles.Boolean}>
				<MaterialCheckbox control={control} name={name} />
			</div>
		</div>);
	}

	if (type === 'select') {
		return (<div className={styles.Field}>
			<div>
				{fieldConfig.label} {tooltip && <QuestionMarkTooltip title={tooltip} />}
			</div>
			<MaterialSelectField control={control} name={name} menuItems={fieldConfig.options} className={styles.Select} />
		</div>);
	}

	return (<div className={styles.Field}>
		<div>
			{fieldConfig.label} {tooltip && <QuestionMarkTooltip title={tooltip} />}
		</div>
			<MaterialNumberField
				control={control}
				name={`${name}__percent`}
				className={styles.Percent}
				placeholder="+/-XX"
			/>
			<div className={styles.PercentUnit}>%</div>
		<MaterialNumberField
			control={control}
			className={styles.Value}
			name={name}
			InputLabelProps={{ shrink: true }}
			inputProps={{ min: 0 }}
			placeholder={mixed ? '(mixed)' : null}
			factor={factor}
		/>
		<div className={styles.Unit}>{unit || fieldConfig.unit}</div>
	</div>);
});

export default Panel;
