import React from 'react';
import { getComparison } from 'modules/api2/comparison';
import { redirect } from 'react-router-dom';
import { comparisonUrl } from 'modules/routing/simulator';
import LoadingIndicator from 'components/LoadingIndicator';

export async function loader({ params, request }) {
	const comparison = await getComparison(params.comparisonId);
	const url = new URL(request.url)
	return redirect(comparisonUrl(comparison, url.search));
}

export function SimulatorRoutingPage(){
	return <LoadingIndicator />;

}
