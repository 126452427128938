import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import { GET_USER, getUser, LOGIN_USER, LOGOUT_USER, RECOVER_USER, RESET_USER } from './actions';

export default function* authSaga() {
	// eslint-disable-next-line no-constant-condition
	while (true) {
		const {
			loginUserSuccess,
			logoutUserSuccess,
			getUserFailure,
			recoverUserSuccess,
			recoverUserFailure,
			resetUserSuccess,
			resetUserFailure
		} = yield race({
			loginUserSuccess: take(LOGIN_USER.SUCCESS),
			logoutUserSuccess: take(LOGOUT_USER.SUCCESS),
			getUserFailure: take(GET_USER.FAILURE),
			recoverUserSuccess: take(RECOVER_USER.SUCCESS),
			recoverUserFailure: take(RECOVER_USER.FAILURE),
			resetUserSuccess: take(RESET_USER.SUCCESS),
			resetUserFailure: take(RESET_USER.FAILURE)
		});

		if (loginUserSuccess) {
			yield put(getUser());
			window.location = '/';
			toast.success('Login succeeded');
		}
		if (getUserFailure) {
			if (getUserFailure.error && getUserFailure.error === 'You are not authenticated. Please supply the correct credentials.') {
				window.location = '/login';
			} else {
				toast.error('Network error');
			}
		}
		if (logoutUserSuccess) {
			window.location = '/';
		}
		if (recoverUserSuccess) {
			toast.success('Reset password sent');
		}
		if (recoverUserFailure) {
			toast.error(`Password reset failed: ${recoverUserFailure.error}`);
		}
		if (resetUserSuccess) {
			yield put(getUser());
			toast.success('Password reset success');
			window.location = '/';
		}
		if (resetUserFailure) {
			toast.error(`Password reset failed: ${resetUserFailure.error}`);
		}
	}
}
