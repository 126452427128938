import { getComparison, listComparisons } from 'modules/api2/comparison';

export async function comparisonLoader({ params, searchParams = {} }) {
		const comparison = await getComparison(params.comparisonId, searchParams);
	return { comparison };
}

export async function comparisonListLoader({ request }) {
	const url = new URL(request.url);
	const company = url.searchParams.get('company');
	const comparisonList = await listComparisons(company);
	return { comparisonList };
}
