/**
 *
 * UploadButton
 *
 */
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';

/**
 *
 * Accept options
 * application/vnd.ms-excel Excel Files 97-2003 (.xls)
 * .csv CSV
 * application/vnd.openxmlformats-officedocument.spreadsheetml.sheet  Excel Files 2007+ (.xlsx)
 *
 *
 * @param children
 * @param accept
 * @param multiple
 * @param onChange
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const UploadButton = ({
	children,
	accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
	multiple = false,
	onChange,
	...rest
}) => (
	<Button component="label" {...rest} htmlFor="button-file">
		<input accept={accept} style={{ display: 'none' }} id="button-file" type="file" multiple={multiple} onChange={onChange} />
		{children}
	</Button>
);

UploadButton.propTypes = {
	onChange: PropTypes.func.isRequired
};

export default UploadButton;
