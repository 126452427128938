import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import ShareIcon from '@material-ui/icons/Share';
import React, { useEffect, useState } from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import { LogoAnimated } from 'components/Logo';
import PageHeader from 'components/PageHeader';

import InlineEdit from '../SimulatorEditionPage/InlineEdit';
import { useLoaderData, useParams } from 'react-router-dom';
import { getComparison, updateComparison } from 'modules/api2/comparison';
import styles from './styles.scss';
import { useNavigateWithParams } from 'utils/url';


export default function SimulatorCalculatingPage() {
	const { comparison } = useLoaderData();
	const navigate = useNavigateWithParams();
	const { comparisonId } = useParams();
	const [comparisonDescription, setComparisonDescription] = useState(comparison.description || 'Untitled simulation');


	useEffect(() => {
		if (comparison) {
			setComparisonDescription(comparison.description);
		}
	}, [comparison.description]);

	const checkComparisonStatus = async () => {
		const comparison = await getComparison(comparisonId);
		if (comparison.status !== 'INIT') {
			navigate(`/simulator/${comparisonId}`);
	}}

	useEffect(() => {
		const requestTimer = setInterval(checkComparisonStatus, 2000);
		return () => {
			clearInterval(requestTimer);
		};
	}, [comparisonId]);



	document.title = `${comparisonDescription} - Simulation in progress`;

	return (
		<>
			<PageHeader
				backTo="/simulator"
				right={
					<>
						<Button size="large" variant="contained" color="primary" disabled startIcon={<GetAppIcon />}>
							Download
						</Button>
						&nbsp;&nbsp;&nbsp;
						<Button size="large" variant="outlined" disabled startIcon={<ShareIcon />}>
							Share
						</Button>
					</>
				}>
				<InlineEdit value={comparisonDescription}
										onChange={description => setComparisonDescription(description)}
										onBlur={() => updateComparison(comparison.id, { description: comparisonDescription })}
				/>
			</PageHeader>
			<LoadingIndicator />
			<br />
			<br />
			<br />
			<br />
			<div className={styles.Loading}>
				<br />
				<br />
				<LogoAnimated className="finished" />
				<br />
				<br />
				<p>{comparison.status === 'INIT' ? 'Creating simulation may take a few seconds' : 'Simulation in progress'}</p>
			</div>
		</>
	);
}

