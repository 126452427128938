import { MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

export const MaterialSelectField = ({ control, name, label, menuItems, onChange, children, ...rest }) => (
	<Controller
		control={control}
		name={name}
		label={label}
		defaultValue={''}
		onChange={onChange}
		render={({ field }) => {
			return (
				<Select
					{...rest}
					{...field}
					MenuProps={{
						getContentAnchorEl: null // set to null to avoid problem with popover component, can be probably removed with MUI5
					}}
				>
					{menuItems ? menuItems.map(i => (
						<MenuItem key={i} value={i}>
							{i}
						</MenuItem>
					)) : children}
				</Select>
			);
		}}
	/>
);

MaterialSelectField.propTypes = {
	name: PropTypes.string.isRequired,
	control: PropTypes.object.isRequired,
	menuItems: PropTypes.array,
	children: PropTypes.node
};
