import api from './api';

export async function getComparison(id, params = {}) {
	const response = await api.get(`comparison/${id}`, { params });
	return response.data;
}

export const listComparisons = async (company = null) =>
	(await api.get(`company/${company || 'me'}/comparison`)).data;

export const updateComparison = async (id, comparisonParams) =>
	(await api.post(`comparison/${id}`, comparisonParams)).data;

export const createComparison = async (company = null) =>
	(await api.post(`company/${company || 'me'}/comparison`, {})).data;

export const calculateComparison = async (id) =>
	(await api.post(`comparison/${id}/calculate`)).data;

export const copyComparison = async (id) =>
	(await api.post(`comparison/${id}/copy`)).data;

export const deleteComparison = async (id) =>
	(await api.delete(`comparison/${id}`)).data;

export const interruptComparison = async (comp) =>
	(await api.post(`comparison/${comp.id}/interrupt`)).data;
