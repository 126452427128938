import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';

import { mergeClasses } from 'utils/classHelper';
import { LinkWithQuery } from '../Link';
import styles from './styles.scss';

export const SimplePageHeader = ({ className, ...rest }) => <div className={mergeClasses(styles.PageHeader, className)} {...rest} />;

const PageHeader = ({ backTo, children, className, onBack, right, secondLine, ...rest }) => (
	<div className={mergeClasses(styles.PageHeader, className)} {...rest}>
		<Grid container justifyContent="center" alignItems="center">
			{onBack && (
				<Grid item>
					<IconButton onClick={onBack}>
						<ArrowBackIcon />
					</IconButton>
				</Grid>
			)}
			{backTo && (
				<Grid item>
					<IconButton component={LinkWithQuery} to={backTo}>
						<ArrowBackIcon />
					</IconButton>
				</Grid>
			)}
			<Grid item className={styles.Title}>
				{children}
			</Grid>
			{right && <Grid item>{right}</Grid>}
		</Grid>
		{secondLine}
	</div>
);

export default PageHeader;
