export function uppercaseFirst(txt) {
	return txt.charAt(0).toUpperCase() + txt.slice(1); // or if you want lowercase the rest txt.slice(1).toLowerCase();
}

export function camelCaseToTitle(text) {
	let result = text.replace('original.', '');
	result = result.replace(/([A-Z])/g, ' $1').trim();
	result = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
	return result
		.replace('gen lots', 'GenLots')
		.replace('Gen lots', 'GenLots')
		.replace('Comparison', 'Current')
		.replace('mrp', 'MRP')
		.replace('Mrp', 'MRP');
}

export function trim(string, length) {
	if (string.length > length) return `${string.substring(0, length - 3)}...`;
	return string;
}
