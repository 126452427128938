export const download = async (url, name) => {
	// call backend to get file
	const response = await fetch(url, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
			// Authorization: `Bearer ${token}`
		},
		credentials: 'include',
		mode: 'cors'
	});
	if (response.status !== 200) {
		throw new Error();
	}

	// download file xls
	const blob = await response.blob();
	const tmp = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = tmp;
	a.download = name;
	document.body.appendChild(a);
	a.click();
	a.remove();
};
