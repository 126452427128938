/**
 *
 * ResultsChart
 *
 */

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Switch from '@material-ui/core/Switch/Switch';
import Label from '@material-ui/icons/Label';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import c3 from 'c3';
import * as d3 from 'd3';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row } from 'react-flexbox-grid';

import graphConfig from 'components/chart/BarChart/config';
import SvgPatterns from 'components/chart/BarChart/SvgPatterns';
import CustomIcon from 'components/CustomIcon';
import Text from 'components/Text';
import { copyToClipboard } from 'utils/copyToClipboard';
import { mergeClasses } from 'utils/classHelper';
import { daysToHorizon } from 'utils/dates';
import { formatInteger } from 'utils/format';
import { uppercaseFirst } from 'utils/string';
import styles from './styles.scss';

class Chart extends Component {
	zoomFactor = 4;

	// eslint-disable-line react/sort-comp
	toggled = {};

	constructor(props) {
		super(props);
		this.chartId = _.uniqueId('chart-');
		this.state = {
			toggledLabels: false,
			toggledInventory: false
		};
	}

	componentDidMount() {
		this.updateChart();
	}

	// eslint-disable-next-line no-unused-vars
	componentDidUpdate(prevProps, prevState, snapShot) {
		// Only update if data has changed
		// eslint-disable-next-line eqeqeq
		if (this.props.material != prevProps.material) {
			this.updateChart();
		}
	}

	zoomIn() {
		const zoom = this.chart.zoom();
		const newZoom = [zoom[0] + this.zoomFactor, zoom[1] - this.zoomFactor];
		if (newZoom[1] - newZoom[0] > 2) this.chart.zoom(newZoom);
	}

	zoomOut() {
		const zoom = this.chart.zoom();
		const max = this.chart.data()[0].values.length;
		this.chart.zoom([Math.max(zoom[0] - this.zoomFactor, 0), Math.min(zoom[1] + this.zoomFactor, max)]);
	}

	updateChart() {
		const { material } = this.props;

		const keys = [
			'fixedOrderDeliveries',
			'consumptions',
			'consumptions2',
			'consumptions3',
			'comparisonDeliveries',
			'comparisonScrap',
			'comparisonAvailableInventory',
			'comparisonDeliveredInventory',
			'comparisonShiftedInventory',
			'comparisonSafetyStock',
			'genLotsDeliveries',
			'genLotsScrap',
			'genLotsAvailableInventory',
			'genLotsDeliveredInventory',
			'genLotsShiftedInventory',
			'genLotsSafetyStock',
			'qmLotDeliveries'
		];

		let data = Object.assign({}, material.calculation);

		if (material.base.maxInventory) {
			data.comparisonMaxInventory = _.times(data.safetyStock.length, _.constant(material.base.maxInventory));
			keys.splice(10, 0, 'comparisonMaxInventory');
		}
		if (material.maxInventory) {
			data.genLotsMaxInventory = _.times(data.safetyStock.length, _.constant(material.maxInventory));
			keys.push('genLotsMaxInventory');
		}
		const toRename = ['Deliveries', 'Scrap', 'AvailableInventory', 'DeliveredInventory', 'ShiftedInventory', 'safetyStock'];

		// Rename base fields
		data = _.mapKeys(data, (v, k) => {
			if (toRename.indexOf(k) !== -1) return `genLots${uppercaseFirst(k)}`;
			return k;
		});

		// Rename and inject comparison fields
		const comparison = _.mapKeys(material.base.calculation, (v, k) => `comparison${uppercaseFirst(k)}`);
		data = { ...data, ...comparison };

		// Invert some columns
		data.genLotsScrap = data.genLotsScrap.map(v => -v);
		data.comparisonScrap = data.comparisonScrap.map(v => -v);
		data.consumptions = data.consumptions.map(v => -v);
		if (data.shiftedConsumptions) data.shiftedConsumptions = data.shiftedConsumptions.map(v => -v);

		data.consumptions2 = data.consumptions;
		data.consumptions3 = data.consumptions;

		const horizon = daysToHorizon(material.horizon, material.frequency);
		const supplierLeadTime = daysToHorizon(material.supplierLeadTime, material.frequency);
		const columns = keys.map(key => [key, ...(data[key] ? data[key].slice(0, horizon) : [])]);

		const regions = [
			{
				axis: 'x',
				start: -0.5,
				end: Math.ceil(supplierLeadTime) - 0.5,
				label: 'Planned delivery time',
				vertical: true,
				class: 'supplier-lead-time-line'
			}
		];

		// Find end of year indices
		if (material.endOfYear) {
			const indexes = _.keys(_.pickBy(data.labels.slice(1), l => l.includes('W 01') || l.includes('M 01') || l.includes('D 01-01')));
			indexes.forEach(r => {
				regions.push({
					axis: 'x',
					start: +r - 0.5,
					end: +r + 0.5,
					class: 'end-of-year',
					label: 'End of year',
					vertical: true
				});
			});
		}

		this.chart = c3.generate({
			...graphConfig,
			axis: {
				x: { type: 'category', categories: data.labels, tick: { rotate: -45, multiline: false, centered: true } },
				y: { label: { text: material.unit, position: 'outer-middle' } }
			},
			size: { height: 550 },
			bindto: `#${this.chartId}`,
			subchart: { show: material.frequency !== 'DAILY' },
			padding: { left: 80, top: 10, bottom: 0 },
			// interaction: { enabled: material.frequency !== 'DAILY' },
			data: {
				...graphConfig.data,
				columns
			},
			regions,
			onrendered() {
				// Dotted x-axis line
				this.axes.x.selectAll('.tick line').attr('y2', 1);

				// Add labels on GenLots orders
				if (this.isTargetToShow('genLotsDeliveries')) {
					const container = d3.select(this.config.bindto);
					d3.selectAll('.genLotDeliveriesLabel').remove();
					const arcs = d3.selectAll('.c3-shapes-genLotsDeliveries path');
					arcs.each((o, i, nodes) => {
						const delivered = data.genLotsDeliveries[i];
						if (delivered) {
							const bBox = nodes[i].getBBox();
							container
								.append('div')
								.attr('class', 'genLotDeliveriesLabel')
								.html(
									`<span>${data.orderDates[i].replace(' ', '')}</span>` +
									`<span>${formatInteger(delivered)} ${material.unit || 'kg'}</span>`
								)
								.style('left', `${bBox.x + (this.config.padding_left || 0)}px`)
								.style('top', `${bBox.y}px`)
								.on('click', () => copyToClipboard(delivered));
						}
					});
				}
			}
		});
		this.chart.internal.hideLegend(['consumptions2', 'consumptions3']);
		this.chart.hide(['genLotsShiftedInventory', 'comparisonShiftedInventory']);
	}

	focus(id) {
		if (id === 'consumptions') {
			this.chart.focus(['consumptions', 'consumptions2', 'consumptions3']);
		} else {
			this.chart.focus(id);
		}
	}

	blur() {
		this.chart.revert();
	}

	toggle(id, e) {
		const c = this.chart;
		const i = c.internal;
		let toShow = [];
		let toHide = [];

		this.toggled[id] = !this.toggled[id];

		function doToggle(ids, value) {
			if (value) {
				toShow = toShow.concat(ids);
			} else {
				toHide = toHide.concat(ids);
			}
		}

		if (id === 'consumptions') {
			doToggle(['consumptions', 'consumptions2', 'consumptions3'], !i.isTargetToShow('consumptions'));
		} else {
			this.chart.toggle(id);
		}
		if (!this.toggled.consumptions) {
			doToggle(['consumptions'], i.isTargetToShow('qmLotDeliveries') || i.isTargetToShow('fixedOrderDeliveries'));
			doToggle(['consumptions2'], i.isTargetToShow('genLotsDeliveries') || i.isTargetToShow('genLotsScrap'));
			doToggle(['consumptions3'], i.isTargetToShow('comparisonDeliveries') || i.isTargetToShow('comparisonScrap'));
		}

		c.show(toShow);
		c.hide(toHide);

		// Toggle class in legend
		e.currentTarget.classList.toggle('Toggled');
	}

	render() {
		const { material } = this.props;
		const { toggledLabels } = this.state;
		const that = this;
		const showMaxInventory = material.maxInventory || material.base.maxInventory;

		// Legend component
		function Legend({ id, children }) {
			return (
				<Col
					xs={2}
					sm
					className={styles.ColInfo}
					onClick={e => that.toggle(id, e)}
					onMouseOver={() => that.focus(id)}
					onMouseOut={() => that.blur()}>
					<div>{children}</div>
				</Col>
			);
		}

		return (
			<div className={mergeClasses(styles.ResultsChart, toggledLabels ? styles.ToggledLabels : null)}>
				<div className={styles.Toolbar}>
					<ButtonGroup>
						<Button
							onClick={() => this.setState({ toggledLabels: !toggledLabels })}
							startIcon={<Label />}
							style={toggledLabels ? { backgroundColor: 'lightgray' } : {}}
							title="Display labels"
						/>
						<Button startIcon={<ZoomIn />} onClick={() => this.zoomIn()} />
						<Button startIcon={<ZoomOut />} onClick={() => this.zoomOut()} />
					</ButtonGroup>
				</div>

				<div className={styles.Legend}>
					<Row>
						<Col xs={2} sm className={styles.GenLotsLabel}>
							Simulation
						</Col>
						<Legend id="genLotsDeliveries">
							<span className={mergeClasses(styles.Preview, styles.Square, styles.GenLotsOrders)} />
							<Text className={styles.Text}>Deliveries</Text>
						</Legend>
						<Legend id="genLotsScrap">
							<svg className={mergeClasses(styles.Preview, styles.genLotsScrap)}>
								<rect width="20" height="20" />
							</svg>
							<Text className={styles.Text}>Scrap</Text>
						</Legend>
						<Legend id="genLotsAvailableInventory">
							<CustomIcon icon="line" className={mergeClasses(styles.Preview, styles.GenLotsAvailableInventory)} />
							<Text className={styles.Text}>Available inventory</Text>
						</Legend>
						{this.state.toggledInventory ? (
							<Legend id="genLotsShiftedInventory">
								<CustomIcon icon="dots" className={mergeClasses(styles.Preview, styles.GenLotsShiftedInventory)} />
								<Text className={styles.Text}>Shifted avail. inv.</Text>
							</Legend>
						) : (
							<Legend id="genLotsDeliveredInventory">
								<CustomIcon icon="dashedLine" className={mergeClasses(styles.Preview, styles.GenLotsDeliveredInventory)} />
								<Text className={styles.Text}>Delivered inventory</Text>
							</Legend>
						)}
						<Legend id="genLotsSafetyStock">
							<CustomIcon icon="dots" className={mergeClasses(styles.Preview, styles.SafetyStock)} />
							<Text className={styles.Text}>Safety stock</Text>
						</Legend>
						{showMaxInventory ? (
							<Legend id="genLotsMaxInventory">
								<CustomIcon icon="dots" className={mergeClasses(styles.Preview, styles.MaxInventory)} />
								<Text className={styles.Text}>Max. inventory</Text>
							</Legend>
						) : (
							<Col xs={2} sm />
						)}
					</Row>

					<Row>
						<Col xs={2} sm className={styles.ComparisonLabel}>
							Base
						</Col>
						<Legend id="comparisonDeliveries">
							<span className={mergeClasses(styles.Preview, styles.Square, styles.PlannedOrders)} />
							<Text className={styles.Text}>Deliveries</Text>
						</Legend>
						<Legend id="comparisonScrap">
							<svg className={mergeClasses(styles.Preview, styles.comparisonScrap)}>
								<rect width="20" height="20" />
							</svg>
							<Text className={styles.Text}>Scrap</Text>
						</Legend>
						<Legend id="comparisonAvailableInventory">
							<CustomIcon icon="line" className={mergeClasses(styles.Preview, styles.PlannedOrdersAvailableInventory)} />
							<Text className={styles.Text}>Available inventory</Text>
						</Legend>
						{this.state.toggledInventory ? (
							<Legend id="comparisonShiftedInventory">
								<CustomIcon icon="dots" className={mergeClasses(styles.Preview, styles.ComparisonShiftedInventory)} />
								<Text className={styles.Text}>Shifted avail. inv.</Text>
							</Legend>
						) : (
							<Legend id="comparisonDeliveredInventory">
								<CustomIcon icon="dashedLine" className={mergeClasses(styles.Preview, styles.PlannedOrdersDeliveredInventory)} />
								<Text className={styles.Text}>Delivered inventory</Text>
							</Legend>
						)}
						{/* {data.comparisonSafetyStock ? ( */}
						<Legend id="comparisonSafetyStock">
							<CustomIcon icon="dots" className={mergeClasses(styles.Preview, styles.ComparisonSafetyStock)} />
							<Text className={styles.Text}>Safety stock</Text>
						</Legend>
						{showMaxInventory ? (
							<Legend id="comparisonMaxInventory">
								<CustomIcon icon="dots" className={mergeClasses(styles.Preview, styles.ComparisonMaxInventory)} />
								<Text className={styles.Text}>Max. inventory</Text>
							</Legend>
						) : (
							<Col xs={2} sm />
						)}
					</Row>

					<Row>
						<Col xs={2} sm />
						<Legend id="consumptions">
							<span className={mergeClasses(styles.Preview, styles.Square, styles.Consumption)} />
							<Text className={styles.Text}>{this.state.toggledInventory ? 'Shifted consumption' : 'Consumption'}</Text>
						</Legend>
						<Legend id="fixedOrderDeliveries">
							<span className={mergeClasses(styles.Preview, styles.Square, styles.POItemsOrders)} />
							<Text className={styles.Text}>Fixed order deliveries</Text>
						</Legend>
						<Legend id="qmLotDeliveries">
							<span className={mergeClasses(styles.Preview, styles.Square, styles.QMLotOrders)} />
							<Text className={styles.Text}>QM Lot deliveries</Text>
						</Legend>
						<Col xs={2} sm />
						<Col xs={2} sm />
					</Row>

					{!!material.safetyTime && (
						<FormControlLabel
							label="Shift by safety time"
							className={styles.InventorySlide}
							control={
								<Switch
									onChange={(event, value) => {
										const consumptions = (value ? material.calculation.shiftedConsumptions : material.calculation.consumptions).map(
											v => -v
										);
										this.chart.load({
											columns: [['consumptions', ...consumptions], ['consumptions2', ...consumptions], ['consumptions3', ...consumptions]]
										});
										const on = ['genLotsShiftedInventory', 'comparisonShiftedInventory'];
										const off = ['genLotsDeliveredInventory', 'comparisonDeliveredInventory'];
										this.chart.show(value ? on : off);
										this.chart.hide(value ? off : on);
										this.setState({ toggledInventory: value });
									}}
								/>
							}
						/>
					)}
				</div>

				<div id={this.chartId} />

				<SvgPatterns />
			</div>
		);
	}
}

Chart.propTypes = {
	material: PropTypes.object.isRequired
};

export default Chart;
