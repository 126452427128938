import PropTypes from 'prop-types';

const sharedPropTypes = {
	control: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	showErrors: PropTypes.bool,
	rules: PropTypes.object,
	onChange: PropTypes.func,
	className: PropTypes.string,
	format: PropTypes.string,
	inline: PropTypes.bool
};

export default sharedPropTypes
