import React from 'react';

import ToggleButton from 'components/button/ToggleButton';
import { mergeClasses } from 'utils/classHelper';

import styles from './styles.scss';

const StatusFilter = ({ className, color, ...rest }) => (
	<ToggleButton {...rest} className={mergeClasses(styles.StatusFilter, styles[color], className)} />
);

export default StatusFilter;
