import ToggleButtonO from '@material-ui/lab/ToggleButton';
import React from 'react';

const ToggleButton = ({ fullWidth, startIcon, disableElevation, endIcon, value = '', children, ...rest }) => (
	<ToggleButtonO value={value} {...rest}>
		{startIcon && <span style={{ marginRight: 6 }}> {startIcon} </span>}
		{children}
		{endIcon && <span style={{ marginLeft: 6 }}> {endIcon} </span>}
	</ToggleButtonO>
);

export default ToggleButton;
