/**
 *
 * ReactTable defaults and helpers
 *
 */

import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import ReactTableO, { ReactTableDefaults } from 'react-table';

import { LinkWithQuery } from 'components/Link';
import { Checkbox } from 'components/Menu/ColumnMenu';
import 'react-table/react-table.css';
import { mergeClasses } from 'utils/classHelper';
import { formatDate, frequencyToUnit } from 'utils/dates';
import { formatCO2, formatPrice, formatRoundedCurrency, formatShortNumber, formatShortQuantity } from 'utils/format';
import styles from './styles.scss';

export const columnKey = c => (c.id ? c.id : c.accessor);

export const TableDefault = {
	...ReactTableDefaults,
	className: '-hover',
	keyField: 'id',
	minRows: 0,
	column: {
		...ReactTableDefaults.column,
		minWidth: 80,
		Filter: ({ filter, onChange }) => (
			<input type="text" placeholder="" value={filter ? filter.value : ''} onChange={event => onChange(event.target.value)} />
		)
		// filterMethod: filterWords, // Case insensitive filtering
	},
	nextText: (
		<Tooltip title="Next page">
			<ChevronRightIcon />
		</Tooltip>
	),
	previousText: (
		<Tooltip title="Previous page">
			<ChevronLeftIcon />
		</Tooltip>
	),
	rowsText: '',
	TrComponent: ({ to, className, ...rest }) =>
		to ? (
			<LinkWithQuery className={mergeClasses('rt-tr', className)} {...rest} to={to} />
		) : (
			<div className={mergeClasses('rt-tr', className)} {...rest} />
		)
};

export const ReactTable = props => <ReactTableO {...TableDefault} {...props} />;

export default ReactTable;

export const SelectInput = ({ checked, id, onClick, row }) => (
	<div className={mergeClasses(styles.SelectInput, checked ? styles.Checked : '')} onClick={() => onClick(checked, id, row)} />
);
// export const SelectInput = ({ checked, id, onClick, row }) => <Checkbox checked={checked} onClick={() => onClick(checked, id, row)} />;

export const booleanCell = ({ value }) => <Checkbox checked={value} />;

const numberCell = (value, unit = '', format = formatShortNumber, className = null) => {
	if (value === null) {
		return <></>;
	}
	return (
		<>
			<div className={mergeClasses(styles.Number, className)}>{format(value)}</div>
			<div className={styles.Unit}>{unit}</div>
		</>
	);
};

export const coloredCell = (value, unit, format) =>
	numberCell(value, unit, format, value > 0 ? styles.Positive : value < 0 ? styles.Negative : null);

export const cell = unit => ({ value }) => numberCell(value, unit);

export const currencyCell = ({ value, original: { currency } }) => numberCell(value, currency, formatRoundedCurrency);

export const exactCurrencyCell = ({ value, original: { currency } }) => numberCell(value, currency, formatPrice);

export const coloredCurrencyCell = ({ value, original: { currency } }) => coloredCell(value, currency, formatRoundedCurrency);

export const unitCell = ({ value, original: { unit } }) => numberCell(value, unit, formatShortQuantity);

export const percentCell = ({ value }) => numberCell(100 * value, '%', formatShortNumber);

export const coloredPercentCell = ({ value }) => coloredCell(100 * value, '%', formatShortNumber);

export const frequencyCell = ({ value, original: { frequency } }) => numberCell(value, frequencyToUnit(frequency));

export const co2Cell = ({ value }) => numberCell(value, 'tC02e', formatCO2);

export const dateCell = ({ value }) => numberCell(value, '', formatDate);

export const supplierCell = ({ value }) => {
	if (value && value[0]) {
		const add = value.length > 1 ? ` (+${value.length - 1})` : '';
		const tooltip = value.map(i => i.name).join('\n');
		return (
			<span title={tooltip}>
				{value[0].name} {value[0].country ? `(${value[0].country})` : ''}
				{add}
			</span>
		);
	}
	return null;
};

export const ellipsisCell = ({ value }) => <span title={value}>{value}</span>;

export const Footer = (tableCell, totalFn = _.sum) => ({ data, column }) => {
	if (!data.length) return '';

	const sum = totalFn(data.map(row => row[columnKey(column)]));
	return tableCell({ value: sum, original: data[0] });
};

export const currencyFooter = Footer(currencyCell);
export const currencyAverageFooter = Footer(currencyCell, _.mean);
export const co2Footer = Footer(currencyCell);
