import { useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export function getSearchParameter(name) {
	return new URLSearchParams(window.location.search).get(name);
}

export function useQuery() {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
}

// Flask decode one so if you send one encode / become %2F that becomes a / so we need double encoding
// https://github.com/pallets/flask/issues/900
export const multipleEncode = name => encodeURIComponent(encodeURIComponent(name));

export function useNavigateWithParams() {
	// conserves the search params so we can use the company parameter consistently
	const navigate = useNavigate();
	const [currentSearchParams] = useSearchParams();

	return (path, options = {}) => {
		if (path === 0) return navigate(0, options); //case for refreshing the page
		const { exclude = [] } = options;
		const [basePath, pathSearchString = ''] = path.split('?');
		const newSearchParams = new URLSearchParams(pathSearchString);

		// Merge current params into new params
		currentSearchParams.forEach((value, key) => {
			if (!newSearchParams.has(key)) newSearchParams.append(key, value);
		});

		exclude.forEach(key => newSearchParams.delete(key)); // Remove excluded params

		const finalPath = newSearchParams.toString() ? `${basePath}?${newSearchParams}` : basePath;
		// make sure we pass the right url
		navigate(finalPath, options);
	};
}
