import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { mergeClasses } from 'utils/classHelper';
import styles from './styles.scss';
import sharedPropTypes from '../sharedPropTypes';

export const MaterialInputField = ({
																		 control,
																		 name,
																		 className,
																		 defaultValue = '',
																		 inline = false,
																		 type = 'text',
																		 factor,
																		 inputProps,
																		 ...rest
																	 }) => {


	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field: { ref, value, onChange }, fieldState: { error } }) => (
				<TextField
					className={mergeClasses(styles.MaterialTextField, className, inline ? styles.Inline : null)}
					type={type}
					value={(factor ? value * factor : value) || ''}
					onChange={(e) => {
						const inputValue = e.target.value;
						const valueToStore = factor && inputValue ? inputValue / factor : inputValue;
						onChange(valueToStore);
					}}
					error={!!error}
					helperText={error ? error.message : ''}
					inputProps={inputProps}
					{...rest}
					ref={ref}
				/>
			)}
		/>
	);
};

MaterialInputField.propTypes = { ...sharedPropTypes, inputProps: PropTypes.object, factor: PropTypes.number };

export const MaterialTextField = (props) => (
	<MaterialInputField type="text" {...props} />
);

MaterialTextField.propTypes = { ...sharedPropTypes };

export const MaterialNumberField = ({ defaultValue, ...props }) => (
	<MaterialInputField type="number"
											defaultValue={defaultValue ? parseFloat(defaultValue) : defaultValue}
											{...props} />
);


export const MaterialIntegerField = ({ defaultValue, ...props }) => (
	<MaterialInputField type="number"
											defaultValue={defaultValue ? parseInt(defaultValue) : defaultValue}
											{...props} />
);
