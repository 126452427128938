import React from 'react';

export default () => (
	<svg id="pattern" style={{ height: '0' }}>
		<defs>
			<pattern id="genLotsScrapPattern" patternUnits="userSpaceOnUse" width="10" height="10" patternTransform="rotate(45)">
				<line x1="0" y="0" x2="0" y2="10" stroke="#E44600" strokeWidth="20" />
				<line x1="0" y="0" x2="0" y2="10" stroke="#5B63B6" strokeWidth="16" />
			</pattern>
			<pattern id="comparisonScrapPattern" patternUnits="userSpaceOnUse" width="10" height="10" patternTransform="rotate(45)">
				<line x1="0" y="0" x2="0" y2="10" stroke="#E44600" strokeWidth="20" />
				<line x1="0" y="0" x2="0" y2="10" stroke="#C0C0C0" strokeWidth="16" />
			</pattern>
		</defs>
	</svg>
);
