import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';

import styles from './styles.scss';

export default function ColumnMenu({ button, columns, title, onSelect, onSelectAll }) {
	const [anchorEl, setAnchorEl] = useState(null);

	return (
		<>
			{button ? (
				<Button size="small" endIcon={<ExpandMoreIcon />} onClick={event => setAnchorEl(event.currentTarget)}>
					{title}
				</Button>
			) : (
				<>
					<ExpandMoreIcon className={styles.Anchor} fontSize="small" onClick={event => setAnchorEl(event.currentTarget)} />
					{title}
				</>
			)}
			<Menu
				anchorEl={anchorEl}
				className={styles.Menu}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				getContentAnchorEl={null}>
				<MenuItem onClick={() => onSelectAll(columns)} style={{ fontWeight: 500 }}>
					<Checkbox checked={!columns.find(i => !i.show)} />
					{'  '}
					All
				</MenuItem>
				{columns.map(i => (
					<MenuItem key={i.accessor} onClick={() => onSelect(i)}>
						<Checkbox checked={i.show} />
						{'  '}
						{i.Header}
					</MenuItem>
				))}
			</Menu>
		</>
	);
}

export function Checkbox({ checked, label, ...rest }) {
	const Component = checked ? CheckBoxIcon : CheckBoxOutlineBlankIcon;
	const icon = (
		<Component fontSize="small" color="primary" className={styles.Checkbox} style={label ? { marginRight: 6 } : null} {...rest} />
	);
	if (label) {
		return (
			<>
				{icon}
				{label}
			</>
		);
	}
	return icon;
}
