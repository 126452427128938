// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-Menu-styles-Anchor{border:1px solid #7d87a7;border-radius:3px;font-size:13px;height:15px;margin:-3px 5px 0 0;width:15px}.app-components-Menu-styles-Menu .MuiPaper-root{padding:5px}.app-components-Menu-styles-Menu li{padding:6px 10px}.app-components-Menu-styles-Menu li svg{margin-right:6px}.app-components-Menu-styles-Checkbox{cursor:pointer;color:rgba(91,99,182,.9) !important}", "",{"version":3,"sources":["webpack://./app/components/Menu/styles.scss"],"names":[],"mappings":"AAEA,mCACC,wBAAA,CACA,iBAAA,CACA,cAAA,CACA,WAAA,CACA,mBAAA,CACA,UAAA,CAIA,gDACC,WAAA,CAGD,oCACC,gBAAA,CAEA,wCACC,gBAAA,CAKH,qCACC,cAAA,CACA,mCAAA","sourcesContent":["@import \"style/variables.scss\";\n// Drop down menu\n.Anchor {\n\tborder: 1px solid #7D87A7;\n\tborder-radius: 3px;\n\tfont-size: 13px;\n\theight: 15px;\n\tmargin: -3px 5px 0 0;\n\twidth: 15px;\n}\n\n.Menu {\n\t:global .MuiPaper-root {\n\t\tpadding: 5px;\n\t}\n\n\tli {\n\t\tpadding: 6px 10px;\n\n\t\tsvg {\n\t\t\tmargin-right: 6px;\n\t\t}\n\t}\n}\n\n.Checkbox {\n\tcursor: pointer;\n\tcolor: transparentize($blueViolet, 0.1) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Anchor": "app-components-Menu-styles-Anchor",
	"Menu": "app-components-Menu-styles-Menu",
	"Checkbox": "app-components-Menu-styles-Checkbox"
};
export default ___CSS_LOADER_EXPORT___;
